import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'

type FetchIndividualGoalExpectationsParams = {
  user_id: string
  initial_date: string
  final_date: string
}

type FetchIndividualGoalExpectationsResponse = {
  monthly_revenue: number
  user_monthly_revenue: number
  todayGoalExpectations: {
    value: number
    date: string
    percentage: number
  }
  monthlyGoalExpectations: {
    value: number
    date: string
    percentage: number
  }
}

async function fetchIndividualGoalExpectations(
  params: FetchIndividualGoalExpectationsParams,
): Promise<FetchIndividualGoalExpectationsResponse> {
  const { data: response } = await apiServer.get('/individual-goal-expectations', {
    params: {
      ...params,
    },
  })

  const { data } = response

  return data
}

export function useGetIndividualGoalExpectations(
  params: FetchIndividualGoalExpectationsParams,
): UseQueryResult<FetchIndividualGoalExpectationsResponse> {
  return useQuery(['individualGoalExpectations', params], () => fetchIndividualGoalExpectations(params), {
    staleTime: 1000 * 60 * 10,
  })
}
