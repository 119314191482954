import {
  Box,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FreightDocuments } from '../../../components/FreightDocuments'
import { MonitoringOccurrences } from '../../../components/MonitoringOccurrences'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { useGetOneFreight } from '../../../services/endpoints/freights'
import { useGetFreightHistory } from '../../../services/endpoints/freights/getHistoryFreight'
import { setFreightStatusHistory, setFreightSuccess } from '../../../store/slices/freightSlice'
import { ListTrackerDevices } from '../../tracker-devices'
import { ListFreightExpenseByFreight } from '../financial/show/cards/ListFinancialTitleByFreight'
import { CompatibleMotorists } from '../show/cards/compatible-motorists'
import FreightOccurrencesCard from '../show/cards/freight-occurrences'
import FreighStatusTimeline from '../show/cards/freight-status-timeline'
// import { GeneralInfos } from '../show/cards/freight-info/GeneralInfos'
import { InteractionsTimeline } from '../show/cards/interaction-timeline'
import { ListStub } from '../show/cards/list-stub'
import { ListOldStubs } from '../show/cards/list-stub/list-old-stubs'
import FreighMonitoringBuonny from '../show/cards/monitoring/FreighMonitoringBuonny'
import FreighMonitoringWhatsapp from '../show/cards/monitoring/FreighMonitoringWhatsapp'
import { SelectedMotorist } from '../show/cards/selected-motorist'
import FreightEstimate from './cards/FreightEstimate'
import FreightInfoDetails from './cards/FreightInfoDetails'
import OrigenAndDestination from './cards/OrigenAndDestination'
import Request from './cards/Request'
import GeneralInfos from './GeneralInfos'
import Header from './Header'

interface RequestParams {
  freight_id: string
}

const FreightDetails = (): JSX.Element => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(0)
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const { freight_id } = useParams<RequestParams>()

  const {
    data: freight,
    isLoading,
    isFetching,
    error,
    isError,
  } = useGetOneFreight({
    freight_id,
    relations: [
      'creator',
      'origin',
      'destination',
      'client',
      'client_contact',
      'client_feedback',
      'seller',
      'motorist',
      'attachments',
      'motorist.address_city',
      'motorist.address_city.state',
      'motorist.driver_license',
      'vehicle',
      'vehicle.owner',
      'vehicle.antt_owner',
      'vehicle.owner.city',
      'vehicle.vehicle_category',
      'vehicle.vehicle_body',
      'vehicle_categories',
      'vehicle_bodies',
      'trailer',
      'trailer.owner',
      'trailer.owner.city',
      'trailer.vehicle_body',
      'cargo',
      'rate',
      'nps_motorists',
      'ctes',
      'freight_expenses',
      'monitoring_occurrences',
      'monitoring_occurrences.reason',
      'monitoring_occurrences.user',
    ],
  })

  const {
    data: statusHistory,
    error: errorStatusHistory,
    isError: isErrorStatusHistory,
  } = useGetFreightHistory(freight_id)

  dispatch(setFreightStatusHistory(statusHistory))

  if (freight) dispatch(setFreightSuccess(freight))

  if (isError) toastError({ toast, error })
  if (isErrorStatusHistory) toastError({ toast, error: errorStatusHistory })

  const isFreightValid = ['in_destination', 'delivered', 'finished'].includes(String(freight?.status))

  const tabs = [
    'Informações gerais:',
    'Informações do Motorista:',
    'Documentos:',
    'Despesas:',
    'Dispositivos e Monitoramento:',
    'Interações:',
    'Ocorrências:',
  ]

  return (
    <Layout>
      {isFetching && !isLoading && (
        <Flex align="center" justify="center">
          <Spinner size="lg" my={10} />
        </Flex>
      )}
      {isLoading ? (
        <Flex align="center" justify="center">
          <Spinner size="lg" mt={10} />
        </Flex>
      ) : (
        freight && (
          <VStack spacing={4} align="stretch">
            <Header freight={freight} />
            <GeneralInfos freight={freight} />
            <FreighStatusTimeline freight={freight} />
            <FreighMonitoringWhatsapp freight={freight} />
            <FreighMonitoringBuonny freight={freight} />

            {isFreightValid && isAfter(new Date(freight.created_at_default), new Date('2023-10-25')) ? (
              <ListStub freight={freight} bg={bg} />
            ) : (
              isFreightValid && <ListOldStubs freight={freight} bg={bg} />
            )}

            <Tabs colorScheme="orange" w="full">
              <TabList w="full" overflowX="auto" overflowY="hidden">
                {tabs.map((tab, i) => (
                  <Tab
                    fontSize="sm"
                    key={tab}
                    _active={{ outline: 'none' }}
                    _focus={{ outline: 'none' }}
                    _hover={{ outline: 'none', bg: 'none' }}
                    whiteSpace="nowrap"
                    onClick={() => setActiveTab(i)}
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>

              <TabPanels>
                {activeTab === 0 && (
                  <Box mt="4">
                    <FreightInfoDetails freight={freight} />
                    <FreightEstimate freight={freight} />
                    <Request freight={freight} />
                    <OrigenAndDestination freight={freight} />
                  </Box>
                )}
                {activeTab === 1 && (
                  <Box mt="4">
                    {freight && !freight.motorist_id && <CompatibleMotorists freight={freight} bg={bg} />}
                    {freight.motorist && <SelectedMotorist freight={freight} bg={bg} />}
                  </Box>
                )}
                {activeTab === 2 && <FreightDocuments freight_id={freight.id} />}
                {activeTab === 3 && (
                  <>
                    <ListFreightExpenseByFreight freight={freight} bg={bg} />
                  </>
                )}
                {activeTab === 4 && (
                  <Box mt="4">
                    <MonitoringOccurrences isLoading={isLoading} freight={freight} bg={bg} />
                    <ListTrackerDevices freight={freight} bg={bg} />
                  </Box>
                )}
                {activeTab === 5 && (
                  <Box mt="4">
                    <InteractionsTimeline freight={freight} bg={bg} />
                  </Box>
                )}
                {activeTab === 6 && (
                  <Box mt="4">
                    <FreightOccurrencesCard freight={freight} bg={bg} />
                  </Box>
                )}
              </TabPanels>
            </Tabs>
          </VStack>
        )
      )}
    </Layout>
  )
}

export default FreightDetails
