import { addDays, addHours } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import ptLocale from 'date-fns/locale/pt-BR'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { CiotType, ResponseListApiType } from '../../types'
import {
  getClientPaymentDate,
  getFinancialExpense,
  getPocketInsurancePercent,
  getPocketInsuranceValue,
  getTakerAlias,
} from '../../utils/cteReportHelpers'
import { formatDateToBR } from '../../utils/dates/formatDateToCountry'
import formatFreightType from '../../utils/formatFreightType'
import { FormattedOneFreight } from '../freights/getOneFreight'

export interface FetchCteReportParams {
  key?: string
  nct?: string
  serie?: number
  emit_date_ini?: string
  emit_date_final?: string
  emitter?: string
  taker?: string
  page?: number
  origin_city_id?: string | number
  destination_city_id?: string | number
  origin_name?: string | number
  destination_name?: string | number
  per_page?: number
  unpaged?: boolean
  status?: string
  type?: string
  freight_id?: string
  operator?: string
  seller?: string
  payment_status?: string
  freight_number?: string
  analist_id?: string
}

interface CteResponse {
  id: string
  key: string
  nct: string
  serie: string
  type: string
  type_spothub: string
  emit_date: Date
  emitter: string
  sender: string
  receiver: string
  operator: string
  origin: string
  destination: string
  taker: string
  taker_cnpj: string
  installment_value: string
  receive_value: string
  status: string
  freight_id: string
  xml_file: string
  created_at: string
  updated_at: string
  xml_file_url: string
  pdf_file_url: string
  freight_url: string
  freight: FormattedOneFreight
  cte: CteResponse | null
  cte_shared_master: CteResponse | null
  is_shared: boolean
  cte_shared_master_id: string
  icms: number
  cargo_value: number
  cargo_weight: number
  nfe_number?: string
  omie_code?: string
  description?: string
  payment_status?: string
  payment_date?: string
  checked_payment_date?: string
  mdfes: {
    number: string
    ciot: string
    ciot_doc?: CiotType
  }[]
}
export interface CteResponseFormatted extends CteResponse {
  client_payment_date?: string
  emitter: string
  creator: string
  par_uf: string
  aliquot_icms: number
  aliquot_iss: number
  pis_confins: string
  seller: string
  client_type: string
  installment_value_formatted: number
  receive_value_formatted: number
  motorist_value_formatted: string
  gross_ciot_value: string
  vehicle_category: string
  vehicle_body: string
  vehicle_weight: string
  sector: string
  owner_type: string
  observation: string
  freight_ref: string
  expenses: string
  mdfe: string
  shared_cte: string
  ciot: string
  client_contact: string
  client_ref_number_formatted: string
  billing_date?: string
  payment_date_formatted?: string
  checked_payment_date_formatted?: string
  taker_alias?: string
  month: number
  is_municipal: string
  pocket_insurance_percent?: number
  pocket_insurance_value?: number
  cprb_expense?: number
  payment_expense?: number
  gris: number
  icms_value?: number
  iss_value?: number
  pis_cofins_value?: number
  icms_value_to_consider?: number
  pis_cofins_freight_contract?: number
  pis_cofins_operational_expenses?: number
  pis_cofins_without_icms?: number
  pis_cofins_insurance_value?: number
  total_taxes?: number
  adjustment_coefficient?: number
  net_profit?: number
  gross_profit: number
  gross_profit_without_pocket: number
  pocket_insurance: number
  financial_expense: number
  bonus: number
  net_contribution_margin_value: number
  net_contribution_margin_percent: number
  gross_contribution_margin: number
  toll_cost: number
}

export function formatObservation(cteType: string, cteRef: string): string {
  if (cteType.includes('Complemento')) return `Complemento da CTE ${cteRef}`
  if (cteType.includes(`Substituição`)) return `Substituição da CTE ${cteRef}`
  if (cteType.includes(`Anulação`)) return `Anulação da CTE ${cteRef}`
  return ''
}

export function formatSector(sector?: string): string {
  if (sector) {
    if (sector.includes('distribution')) return 'Distribuição'
    if (sector.includes('mro')) return 'Suprimentos'
  }
  return ''
}

export function handleFormatDate(value: Date | string): string {
  const formattedDate = value
    ? format(utcToZonedTime(value, 'America/Sao_Paulo'), 'dd/MM/yyyy', {
        timeZone: 'America/Sao_Paulo',
        locale: ptLocale,
      })
    : ''
  return formattedDate
}

function getVehicleWeight(freight?: FormattedOneFreight) {
  const freightType = freight?.type
  const vehicleType = freight?.vehicle?.vehicle_category.type
  if (freightType === 'aerial' || freightType === 'hotline') {
    return 'AÉREO'
  }
  if (vehicleType === 'heavy') {
    return 'PESADO'
  }
  if (vehicleType === 'medium') {
    return 'MÉDIO'
  }
  if (vehicleType === 'light') {
    return 'LEVE'
  }
  return ''
}

function calcFreightExpenseByCte(cte: CteResponse): number {
  if (!cte.freight) {
    return 0
  }
  const result = cte.freight.freight_expenses.reduce((acc, ft) => {
    return acc + Number(ft.value)
  }, 0)
  return result
}

function getCiot(cte?: CteResponse): string {
  if (cte?.mdfes && cte.mdfes.length > 0) {
    return cte.mdfes.map(mdfe => mdfe.ciot).join(',')
  }
  return ''
}

function getCiotEntityInMdfe(cte?: CteResponse): string {
  if (cte?.mdfes && cte.mdfes.length > 0) {
    return cte.mdfes
      .map(mdfe =>
        mdfe.ciot_doc?.gross_ciot_value && mdfe.ciot_doc.ciot_status?.status !== 'cancelado'
          ? mdfe.ciot_doc?.gross_ciot_value
          : '',
      )
      .join(',')
  }
  return ''
}

function getMdfe(cte?: CteResponse): string {
  if (cte?.mdfes && cte.mdfes.length > 0) {
    return cte.mdfes.map(mdfe => mdfe.number).join(',')
  }
  return ''
}

function getEmitterName(name: string) {
  if (name === 'SPOTX LOGISTICA LTDA') return '01 - ES SPOTX'
  const nameMatch = name.match(/.+(\w{2})/)
  if (nameMatch) {
    const names = [
      '01 - ES SPOTX',
      '02 - SP SPOTX',
      '03 - MA SPOTX',
      '04 - DF SPOTX',
      '05 - SC SPOTX',
      '06 - MG SPOTX',
      '07 - MT SPOTX',
      '08 - MS SPOTX',
      '09 - BA SPOTX',
      '10 - RS SPOTX',
      '11 - PR SPOTX',
    ]

    const index = names.findIndex(n => {
      const extraction = n.match(/\d{2} - (\w{2}) SPOTX/)
      if (extraction) return extraction[1] === nameMatch[1]
      return false
    })
    if (index > 0) return names[index]
  }
  return name
}

function getSharedCte(cte?: CteResponse): string {
  if (!cte) return ''
  const firstPart = getEmitterName(cte.emitter)
  let secondPart = cte.nct
  if (cte.cte_shared_master) {
    secondPart = cte.cte_shared_master.nct
  }
  return `${firstPart} | ${secondPart}`
}

function getFreightContractValue(cte: CteResponse): number {
  const freightContracts =
    cte?.freight?.freight_contracts && cte?.freight?.freight_contracts.length > 0
      ? cte?.freight?.freight_contracts
      : []
  if (cte.cte_shared_master) {
    return 0
  }
  return freightContracts.reduce((acc, ft) => {
    return acc + Number(ft.total_value)
  }, 0)
}

function getCteSpothubType(cte: CteResponse): string {
  if (cte.status === 'Refaturamento') return 'Refaturamento'
  if (cte.is_shared) return 'Compartilhado'
  return cte.type
}

export type GetCteResponse = {
  total: number
  data: CteResponseFormatted[]
  sales_report: string[][]
  sales_report_complete: string[][]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatCte(ctesResponse?: any | null) {
  if (!ctesResponse) return []
  return ctesResponse.map((cte: CteResponse) => {
    const isMunicipal = cte.origin === cte.destination
    const ownerType = cte.freight?.vehicle?.owner?.type ? cte.freight?.vehicle?.owner?.type.toUpperCase() : ''
    const originUf = cte.origin.split('-')[1]
    const destinationUf = cte.destination.split('-')[1]
    let vehicleCategory = cte.freight?.vehicle?.vehicle_category
      ? cte.freight?.vehicle?.vehicle_category?.name
      : ''
    if (cte.freight?.type === 'aerial' || cte.freight?.type === 'hotline') {
      vehicleCategory = 'Aéreo'
    }
    const vehicleBody = cte.freight?.vehicle?.vehicle_body ? cte.freight?.vehicle?.vehicle_body?.name : ''
    const vehicleWeight = getVehicleWeight(cte.freight)
    const pisConfins = 0.0925
    const freightContract =
      cte?.freight?.freight_contracts && cte?.freight?.freight_contracts.length > 0
        ? cte?.freight?.freight_contracts[0]
        : null

    const ctesSiblins: CteResponse[] = ctesResponse.filter(
      (ct: CteResponse) => ct.freight?.id === cte.freight?.id && ct.type === 'Normal',
    )
    let expenses = 0.0
    if (ctesSiblins.length === 0) {
      expenses = calcFreightExpenseByCte(cte)
    }
    if (ctesSiblins.length > 0 && ctesSiblins[0].id === cte.id) {
      expenses = calcFreightExpenseByCte(cte)
    }
    const billing_date = addDays(new Date(cte.emit_date), 10)

    const freightValue = Number(cte.installment_value)
    const freightContractValue = getFreightContractValue(cte)
    const pocketInsurancePercent = getPocketInsurancePercent(cte.taker)
    const pocketInsuranceValue = pocketInsurancePercent ? cte.cargo_value * pocketInsurancePercent : 0
    const cprbExpense = -((freightValue * 1.5) / 100)
    const paymentExpense = (freightContractValue + expenses) * 0.003
    const icmsValue = cte.icms ? -((freightValue * cte.icms) / 100) : 0
    const pisCofinsValue = -(freightValue * 0.0925)
    const icmsValueToConsider = icmsValue ? icmsValue * 0.8 : 0
    const pisCofinsFreightContract =
      ownerType === 'PJ' ? freightContractValue * 0.0925 : freightContractValue * 0.069375
    const pisCofinsOperationalExpenses = ownerType === 'PJ' ? expenses * 0.0925 : expenses * 0.069375
    const pisCofinsWithoutIcms = -((freightValue + icmsValue) * 0.0925)
    const pisCofinsInsuranceValue = pocketInsuranceValue * 0.0925
    const tollCost = freightContract?.toll_value ? +freightContract.toll_value : 0
    const totalTaxes =
      cprbExpense +
      pisCofinsValue +
      icmsValueToConsider +
      pisCofinsFreightContract +
      pisCofinsOperationalExpenses +
      pisCofinsInsuranceValue
    const adjustmentCoefficient = -(freightValue * 0.015)
    const netProfit = freightValue + totalTaxes
    const grossProfit =
      netProfit -
      (freightContractValue +
        pisCofinsOperationalExpenses +
        adjustmentCoefficient +
        pocketInsuranceValue +
        cprbExpense +
        paymentExpense +
        26.54 +
        tollCost)
    const clientPaymentDate = getClientPaymentDate(cte.taker_cnpj, new Date(cte.emit_date))
    const pocketInsurance = getPocketInsuranceValue(cte.origin, cte.destination, cte.cargo_value)
    const grossProfitWithoutPocket = grossProfit - pocketInsurance
    const financialExpense = getFinancialExpense(freightValue, cte.emit_date, clientPaymentDate)
    const bonus = freightValue * 0.01
    const netContributionMarginValue = grossProfit - financialExpense + bonus
    const grossContributionMargin = grossProfit - financialExpense + bonus + pocketInsurance
    const netContributionMarginPercent = netContributionMarginValue / netProfit

    return {
      ...cte,
      freight: {
        ...cte.freight,
        type: cte.freight?.type ? formatFreightType(cte.freight.type) : '',
      },
      nct: cte.nct,
      freight_ref: cte.freight?.freight_number ? cte.freight?.freight_number : '',
      client_payment_date: clientPaymentDate,
      emitter: getEmitterName(cte.emitter),
      creator: cte.freight?.creator?.name,
      operator: cte.operator,
      serie: cte.serie,
      type: cte.type,
      type_spothub: getCteSpothubType(cte),
      emit_date: addHours(new Date(cte.emit_date), 4),
      month: new Date(cte.emit_date).getMonth() + 1,
      sender: cte.sender,
      receiver: cte.receiver,
      taker: cte.taker,
      taker_alias: getTakerAlias(cte.taker),
      taker_cnpj: cte.taker_cnpj,
      origin: cte.origin,
      destination: cte.destination,
      is_municipal: isMunicipal ? 'SIM' : 'NÃO',
      par_uf: `${originUf.trim()}-${destinationUf.trim()}`,
      aliquot_icms: cte.icms ? Number(cte.icms) / 100 : 0,
      aliquot_iss: isMunicipal ? 0.05 : 0,
      pis_confins: pisConfins,
      seller: cte.freight?.seller?.name,
      client_type: 'ANTIGO',
      cargo_value: Number(cte.cargo_value),
      cargo_weight: cte.cargo_weight ? +cte.cargo_weight : undefined,
      vehicle_category: vehicleCategory,
      vehicle_body: vehicleBody,
      vehicle_weight: vehicleWeight,
      sector: formatSector(cte.freight?.sector),
      owner_type: ownerType,
      installment_value_formatted: Number(cte.installment_value),
      receive_value_formatted: Number(cte.receive_value),
      motorist_value_formatted: +freightContractValue,
      gross_ciot_value: getCiotEntityInMdfe(cte),
      observation: cte.cte ? formatObservation(cte.type, cte.cte.nct) : '',
      expenses,
      mdfe: getMdfe(cte),
      ciot: getCiot(cte),
      shared_cte: getSharedCte(cte),
      client_contact: cte.freight?.client_contact?.name || '',
      nfe_number: cte.nfe_number,
      client_ref_number_formatted:
        cte.freight && cte.freight.client_ref_number
          ? `${cte.freight.client_ref_type} - ${cte.freight.client_ref_number}`
          : '',
      billing_date,
      payment_date_formatted: cte.payment_date ? formatDateToBR(cte.payment_date) : '',
      checked_payment_date_formatted: cte.checked_payment_date
        ? formatDateToBR(cte.checked_payment_date)
        : '',
      pocket_insurance_percent: pocketInsurancePercent,
      pocket_insurance_value: pocketInsuranceValue,
      cprb_expense: cprbExpense,
      payment_expense: +paymentExpense,
      gris: 26.54,
      icms_value: icmsValue,
      iss_value: '--',
      pis_cofins_value: pisCofinsValue,
      icms_value_to_consider: icmsValueToConsider,
      pis_cofins_freight_contract: pisCofinsFreightContract,
      pis_cofins_operational_expenses: pisCofinsOperationalExpenses,
      pis_cofins_without_icms: pisCofinsWithoutIcms,
      pis_cofins_insurance_value: pisCofinsInsuranceValue,
      total_taxes: totalTaxes,
      adjustment_coefficient: adjustmentCoefficient,
      net_profit: netProfit,
      gross_profit: grossProfit,
      gross_profit_without_pocket: grossProfitWithoutPocket,
      pocket_insurance: pocketInsurance,
      financial_expense: financialExpense || '',
      bonus,
      net_contribution_margin_value: netContributionMarginValue,
      gross_contribution_margin: grossContributionMargin,
      net_contribution_margin_percent: netContributionMarginPercent,
      toll_cost: tollCost,
    }
  })
}

export async function fetchCtes(data: FetchCteReportParams): Promise<GetCteResponse> {
  const params = data && { ...data }

  const { data: ctesResponse } = await apiServer.get<ResponseListApiType<CteResponse[]>>(`/ctes`, {
    params: {
      ...params,
      emit_date_ini: params.emit_date_ini ? `${params.emit_date_ini}T00:00:00` : undefined,
      emit_date_final: params.emit_date_final ? `${params.emit_date_final}T23:59:59` : undefined,
    },
  })

  const responseFormatted = formatCte(ctesResponse.data.data)

  const salesReport: string[][] = responseFormatted.map((report: CteResponseFormatted) => {
    return [
      [report.freight_ref], // FRETE
      [report.nct], // CTE
      [report.emitter], // EMPRESA
      [report.creator], // OPERADOR
      [report.operator], // EMISSOR
      [report.serie], // SÉRIE
      [report.type], // TIPO CTE
      [report.type_spothub], // TIPO SPOTHUB
      [report.status], // STATUS
      [report.emit_date], // EMISSÃO
      [report.sender], // REMETENTE
      [report.receiver], // DESTINATÁRIO
      [report.taker], // TOMADOR
      [report.taker_cnpj], // CNPJ TOMADOR
      [report.client_contact], // SOLICITANTE
      [report.origin], // ORI
      [report.destination], // DES
      [report.par_uf], // parUF
      [report.aliquot_icms], // Alíquota ICMS
      [report.pis_confins], // Alíquota PIS/COFINS Receita
      [report.seller], // VENDEDOR
      [report.client_type], // Tipo de Cliente
      [report.cargo_value], // VALOR NF (R$)
      [report.cargo_weight], // PESO
      [report.vehicle_category], // TIPO EQUIPAMENTO
      [report.vehicle_body], // CARROCERIA
      [report.vehicle_weight], // CATEGORIA
      [report.sector], // MODALIDADE
      [report.owner_type], // ENQUADRAMENTO
      [report.installment_value_formatted], // VALOR FRETE (R$)
      [report.motorist_value_formatted], // VALOR MOTORISTA (R$)
      [report.gross_ciot_value], // VALOR CIOT
      [report.observation], // OBSERVAÇÃO
      [report.expenses], // DESPESAS
      [report.mdfe], // MDFE
      [report.ciot], // CIOT
      [report.key], // Chave
      [report.checked_payment_date], // PREVISÃO PAGAMENTO
      [report.payment_status], // STATUS PAGAMENTO
      [report.payment_date], // DATA PAGAMENTO
    ]
  })

  const salesReportComplete: string[][] = responseFormatted.map((report: CteResponseFormatted) => {
    return [
      [report.freight_ref], // FRETE
      [report.client_contact], // SOLICITANTE
      [report.type], // TIPO CTE PURO
      [report.emitter], // EMPRESA
      [report.creator], // OPERADOR
      [report.operator], // EMISSOR
      [report.nct], // CTE
      [report.serie], // SÉRIE
      [report.type_spothub], // TIPO DE CTE
      [report.shared_cte], // CTE COMPARTILHADO
      [report.month], // MÊS
      [report.emit_date], // EMISSÃO
      [report.sender], // REMETENTE
      [report.receiver], // DESTINATÁRIO
      [report.taker], // TOMADOR
      [report.taker_alias], // CLIENTE TRATADO
      [report.origin], // ORI
      [report.destination], // DES
      [report.is_municipal], // Municipal
      [report.par_uf], // d
      [report.aliquot_icms], // Aliquota ICMS
      [report.aliquot_iss], // Aliquota ISS
      [report.pis_confins], // Alíquota PIS/COFINS Receita
      [report.seller], // VENDEDOR
      [report.client_type], // Tipo de Cliente
      [report.cargo_value], // VALOR NF (R$)
      [report.cargo_weight], // PESO (KG)
      [report.vehicle_category], // TIPO DE EQUIPAMENTO
      [report.vehicle_weight], // CATEGORIA
      [report.sector], // MODALIDADE
      [report.owner_type], // ENQUADR.
      [report.installment_value_formatted], // VALOR FRETE (R$)
      [report.motorist_value_formatted], // CONTRATO DE FRETE (R$)
      [report.expenses], // DESPESAS OPERACIONAIS  (R$)
      ['--'], // DESPESAS REEMBOLSÁVEIS
      [report.pocket_insurance_percent], // SEGURO (%)
      [report.pocket_insurance_value], // VALOR SEGURO (R$)
      [report.cprb_expense], // DESPESA CPRB (R$)
      [report.payment_expense], // DESPESA PAGAMENTO (R$)
      [report.gris], // GRIS E CUSTOS COM CAD (R$)
      [report.icms_value], // ICMS (R$)
      [report.iss_value], // ISS (R$)
      [report.pis_cofins_value], // PIS/COFINS (R$)
      [report.icms_value_to_consider], // ICMS (considerar) (R$)
      [report.pis_cofins_freight_contract], // CONTRATO DE FRETE (crédito PIS/COFINS) (R$)
      [report.pis_cofins_operational_expenses], // DESPESAS OPERACIONAIS (crédito PIS/COFINS) (R$)
      [report.pis_cofins_without_icms], // PIS/COFINS (sem ICMS na BC) (R$)
      [report.pis_cofins_insurance_value], // VALOR SEGURO (crédito PIS/COFINS) (R$)
      [report.total_taxes], // IMPOSTO TOTAL (R$)
      [report.adjustment_coefficient], // Coeficiente ajuste
      [report.net_profit], // RECEITA LÍQUIDA (R$)
      [report.gross_profit], // LUCRO BRUTO (R$)
      [report.gross_profit_without_pocket], // LUCRO BRUTO SEM BOLSÃO
      [report.pocket_insurance], // BOLSÃO SEGURO
      [report.financial_expense], // DESPESA FINANCEIRA (R$)
      [report.bonus], // COMISSÃO / BONUS (R$)
      [report.net_contribution_margin_value], // MARGEM DE CONTRIBUIÇÃO LIQUIDO
      [report.gross_contribution_margin], // MARGEM DE CONTRIBUIÇÃO BRUTO
      [report.net_contribution_margin_percent], // MARGEM CONTRIBUIÇÃO LIQUIDA (5)
      [report.observation], // OBSERVAÇÃO
      [report.toll_cost], // CUSTO PEDÁGIO
    ]
  })

  const { total } = ctesResponse.data

  return {
    total,
    data: responseFormatted,
    sales_report: salesReport,
    sales_report_complete: salesReportComplete,
  }
}

export function useGetCtes(data: FetchCteReportParams): UseQueryResult<GetCteResponse> {
  return useQuery(['list_ctes', data], () => fetchCtes(data), {
    staleTime: 1000 * 60 * 10,
    retry: false,
  })
}
