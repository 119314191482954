import {
  Box,
  Button,
  Icon,
  useDisclosure,
  useToast,
  Text,
  List,
  ListItem,
  Badge,
  HStack,
  Flex,
  Divider,
  Center,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAttachmentLine } from 'react-icons/ri'
import { useState } from 'react'
import { TbTrash } from 'react-icons/tb'
import { toastError } from '../../../../../config/error/toastError'
import {
  FreightOccurrencesModel,
  ICreateFreightOccurrences,
  useCreateFreightOccurrences,
  useDeleteFreightOccurrences,
  useGetAllFreightOccurrences,
} from '../../../../../services/endpoints/freights/freight-occurrences'
import { FreightType } from '../../../../../services/types'
import CreateFreightOccurrencesModal from './modals/CreateFreightOccurrencesModal'
import { FreightOccurrecesTypeEnum } from '../../../../../services/types/EnumTypes'
import { useCreateAttachments } from '../../../../../services/endpoints/freights/freight-attachments/createAttachments'
import { AttachmentIcon } from '../../../../../components/AttachmentIcon'
import { handleDeleteAttachment } from '../../../../../services/endpoints/freights/freight-attachments/deleteAttachment'
import { AlertDialogConfirm } from '../../../../../components/AlertDialogConfirm'
import { Ability } from '../../../../../components/ability'

export const freightOccurrencesType = [
  { label: '', value: '' },
  { label: 'Sinistro', value: 'sinister' },
  { label: 'Atraso', value: 'delay' },
  { label: 'Avaria', value: 'damage' },
  { label: 'Outro', value: 'other' },
]

interface FreightOccurrencesCardProps {
  freight: FreightType
  bg: string
}

type FreightOccurrencesListProps = {
  freightOccurrences: FreightOccurrencesModel[]
  onRefetch: () => void
}

const createFreightOccurrencesFormSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  attachments: yup.mixed(),
})

const FreightOccurrenceList = ({ freightOccurrences, onRefetch }: FreightOccurrencesListProps) => {
  const toast = useToast()
  const [occurrenceId, setOccurrenceId] = useState<string>('')
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const { mutate: deleteFreightOccurrences } = useDeleteFreightOccurrences({
    onSuccess: () => {
      onRefetch()
      toast({
        title: 'Ocorrência de Frete apagada com sucesso!',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const getTypeLabel = (type: FreightOccurrecesTypeEnum) => {
    switch (type) {
      case FreightOccurrecesTypeEnum.SINISTER:
        return { label: 'Sinistro', color: 'red' }
      case FreightOccurrecesTypeEnum.DELAY:
        return { label: 'Atraso', color: 'yellow' }
      case FreightOccurrecesTypeEnum.DAMAGE:
        return { label: 'Dano', color: 'blue' }
      case FreightOccurrecesTypeEnum.OTHER:
        return { label: 'Outro', color: 'gray' }
      default:
        return { label: 'Desconhecido', color: 'gray' }
    }
  }

  const handleDeleteFreightOccurrence = (id: string) => {
    deleteFreightOccurrences({ id })
    onRefetch()
  }

  return (
    <List spacing={3}>
      {freightOccurrences.map(occurrence => {
        const { label, color } = getTypeLabel(occurrence.type as FreightOccurrecesTypeEnum)

        return (
          <ListItem key={occurrence.id} p={3} borderWidth="1px" borderRadius="md">
            <HStack spacing={3} justify="space-between">
              <Flex gridGap={2} alignItems="center" justifyContent="center">
                <Badge colorScheme={color}>{label}</Badge>
                <Text>{occurrence.description}</Text>
                {occurrence.attachments?.map(attachment => (
                  <>
                    <AttachmentIcon
                      attachment_file_url={attachment.attachment_file_url || ''}
                      attach_id={attachment.id}
                      handleDeleteAttachment={async () => {
                        await handleDeleteAttachment(attachment.id)
                        onRefetch()
                      }}
                    />
                  </>
                ))}
              </Flex>
              <Flex>
                <Ability module="operation" action="delete-freight-occurrences">
                  <Tooltip label="Apagar ocorrência" aria-label="Apagar ocorrência" hasArrow placement="top">
                    <Box>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                          setOccurrenceId(occurrence.id)
                          onOpenAlertDialog()
                        }}
                      >
                        <Icon as={TbTrash} />
                      </Button>
                    </Box>
                  </Tooltip>
                </Ability>
              </Flex>
            </HStack>
          </ListItem>
        )
      })}

      <AlertDialogConfirm
        isOpen={isOpenAlertDialog}
        title="Apagar ocorrência"
        description="Tem certeza que deseja apagar essa ocorrência?"
        onClose={onCloseAlertDialog}
        onConfirm={() => {
          handleDeleteFreightOccurrence(occurrenceId)
        }}
      />
    </List>
  )
}

export default function FreightOccurrencesCard({ freight, bg }: FreightOccurrencesCardProps): JSX.Element {
  const {
    data: freightOccurrences,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllFreightOccurrences({
    freight_id: freight.id,
  })
  const toast = useToast()

  const [attachments, setAttachments] = useState<FileList | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: createFreightOccurrences } = useCreateFreightOccurrences({
    onSuccess: () => {
      refetch()
      toast({
        title: 'Ocorrência de Frete Criada com sucesso!',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
      onClose()
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const { handleSubmit, setValue, formState } = useForm<ICreateFreightOccurrences>({
    resolver: yupResolver(createFreightOccurrencesFormSchema),
  })
  const { errors, isSubmitting } = formState
  const { mutate: createAttachments } = useCreateAttachments({
    onSuccess: () => {
      refetch()
      toast({
        title: 'Anexo criado com sucesso!',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleCreateOccurrence: SubmitHandler<ICreateFreightOccurrences> = async data => {
    if (freight) {
      try {
        const { id: freight_id } = freight
        const response = await createFreightOccurrences({
          freight_id,
          type: data.type,
          description: data.description,
        })

        if (attachments && attachments.length > 0 && response.status === 200) {
          createAttachments({
            freight_id,
            attachments,
            freight_occurrences_id: response.data.data.id,
            description: data.description,
          })
        }
        refetch()
      } catch (error) {
        toastError({ toast, error })
      } finally {
        setAttachments(null)
      }
    }
  }

  return (
    <>
      <Box p={4} bg={bg} borderRadius="md" shadow="md">
        <Flex justify="space-between" direction={['column', 'column', 'row']} gridGap={4}>
          <Text fontSize="xl" mb={4}>
            Ocorrências de Frete
          </Text>

          <Button
            size="sm"
            colorScheme="orange"
            onClick={onOpen}
            variant="outline"
            leftIcon={<Icon as={RiAttachmentLine} />}
            isLoading={isSubmitting}
          >
            Adicionar ocorrência
          </Button>
        </Flex>

        <Divider mb={4} />

        {(isFetching || isLoading) && (
          <Center>
            <Spinner />
          </Center>
        )}

        {!isFetching && !isLoading && freightOccurrences?.length ? (
          <FreightOccurrenceList onRefetch={refetch} freightOccurrences={freightOccurrences} />
        ) : (
          <Text>Nenhuma ocorrência encontrada</Text>
        )}
      </Box>

      <CreateFreightOccurrencesModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSubmit}
        setValue={setValue}
        errors={errors}
        isLoading={isLoading}
        handleCreateOccurrence={handleCreateOccurrence}
        setAttachments={setAttachments}
      />
    </>
  )
}
