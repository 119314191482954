import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  ThemeTypings,
} from '@chakra-ui/react'

type ChangePersonModalProps = {
  title?: string
  description?: JSX.Element | string
  onConfirm: () => void
  confirmLabel?: string
  cancelLabel?: string
  colorScheme?: ThemeTypings['colorSchemes']
} & Omit<ModalProps, 'children'>

export const ChangePersonModal = ({
  title,
  description,
  onConfirm,
  confirmLabel = 'Confirmar',
  cancelLabel,
  colorScheme = 'red',
  onClose,
  isOpen,
}: ChangePersonModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          {cancelLabel && (
            <Button onClick={() => onClose()} size="sm">
              {cancelLabel}
            </Button>
          )}
          <Button
            colorScheme={colorScheme}
            size="sm"
            onClick={() => {
              onConfirm()
              onClose()
            }}
            ml={3}
          >
            {confirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
