import { Box, Button, Flex, Grid, GridItem, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import { ChangePersonModal } from '../../ChangePersonModal/ChangePersonModal'
import useSearchAddress from '../../../hooks/useSearchAddress'
import useSearchOwner from '../../../hooks/useSearchOwner'
import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'
import BlackListType from '../../../services/types/BlackListType'
import OwnerType from '../../../services/types/OwnerType'
import { bankList } from '../../../services/utils/bankList'
import { ufOptions } from '../../../services/utils/ufOptions'
import { ShowInfosBlackListModal } from '../../../pages/black-list/modals/ShowInfosBlackListModal'
import { PisConsultationModal } from '../../../pages/freights/pendencies/modals/PisConsultationModal'
import { RntrcConsultationModal } from '../../../pages/freights/pendencies/modals/RntrcConsultationModal'
import { AnttFormValues } from './type'
import { UISelect } from '../../UI/UISelect'
import { UIInput } from '../../UI/UIInput'
import { UISelectAuto } from '../../UI/UISelectAuto'
import { UISelectAsync } from '../../UI/UISelectAsync'

type AnttFormProps = {
  onOwnerAlreadyFound?: (owner: OwnerType | null) => void
  disabled?: boolean
  vehicleLicensePlate?: string
  hasOwner?: boolean
}

export const AnttForm = ({
  onOwnerAlreadyFound,
  disabled,
  vehicleLicensePlate,
  hasOwner,
}: AnttFormProps): JSX.Element => {
  const toast = useToast()
  const { control, watch, setValue, getValues, clearErrors } = useFormContext<AnttFormValues>()
  const ownerType = watch('type')
  const ownerFoundModal = useDisclosure()
  const [ownerAlreadyFound, setOwnerAlreadyFound] = useState<OwnerType | null>(null)
  const [showInfosBlackListModal, setShowInfosBlackListModal] = useState<BlackListType | null>(null)

  const {
    isOpen: isOpenPisConsultation,
    onOpen: onOpenPisConsultation,
    onClose: onClosePisConsultation,
  } = useDisclosure()
  const {
    isOpen: isOpenRntrcConsultation,
    onOpen: onOpenRntrcConsultation,
    onClose: onCloseRntrcConsultation,
  } = useDisclosure()

  const { loadingAddress, searchAddress } = useSearchAddress(
    data => {
      setValue('address', data?.address || '')
      setValue('district', data?.district || '')
      setValue('city', {
        label: data?.city?.label || '',
        value: data?.city?.value || '',
      })
      setValue('city_uf', data?.uf || '')
      toast({
        title: 'Endereço encontrado',
        description: 'Endereço encontrado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    error => {
      toast({
        title: 'Endereço não encontrado',
        description: error?.message || 'Endereço não encontrado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  )

  const { loading: loadingOwner, search: searchOwner } = useSearchOwner({
    onSuccess: onOwnerFound => {
      if (onOwnerFound.owner) {
        ownerFoundModal.onOpen()
        setOwnerAlreadyFound(onOwnerFound.owner)
        return
      }

      if (!onOwnerFound.owner) {
        ownerFoundModal.onOpen()
      }
      if (onOwnerFound.cpnj_person) {
        setValue('document', onOwnerFound.cpnj_person.cnpj)
        setValue('name', onOwnerFound.cpnj_person.name)
        setValue('type', 'pj')
        setValue('phone', onOwnerFound.cpnj_person.phone)
        setValue('zipcode', onOwnerFound.cpnj_person.zipcode)
        setValue('address', onOwnerFound.cpnj_person.address)
        setValue('district', onOwnerFound.cpnj_person.district)
        setValue('address_number', onOwnerFound.cpnj_person.address_number.toString())
        setValue('city', {
          label: onOwnerFound.cpnj_person.city?.label || '',
          value: onOwnerFound.cpnj_person.city?.value || '',
        })
        setValue('city_uf', onOwnerFound.cpnj_person.address_uf)
      }
    },
    onBlackList: data => {
      if (data) {
        setShowInfosBlackListModal(data)
        toast({
          title: 'Proprietário encontrado na lista negra',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
  })

  const resetFilledForm = () => {
    setValue('name', '')
    setValue('phone', '')
    setValue('zipcode', '')
    setValue('address', '')
    setValue('district', '')
    setValue('address_number', '')
    setValue('birth', '')
    setValue('city', {
      label: '',
      value: '',
    })
    setValue('city_uf', '')
    setValue('father_name', '')
    setValue('mother_name', '')
    setValue('email', '')
    setValue('rntrc', '')
    setValue('agency', '')
    setValue('rntrc_type', '')
    setValue('email', '')
    setValue('pis', '')
    setValue('rg_ie', '')
    setValue('rg_dispatcher', '')
    setValue('rg_dispatch_date', '')
    setValue('rg_uf', '')
    setValue('bank', {
      label: '',
      value: '',
    })
    setValue('account', '')
    setValue('account_type', '')
  }

  return (
    <Box pointerEvents={disabled ? 'none' : 'auto'} opacity={disabled ? 0.5 : 1}>
      {showInfosBlackListModal && (
        <ShowInfosBlackListModal
          isOpen={showInfosBlackListModal !== null}
          onClose={() => setShowInfosBlackListModal(null)}
          black_list={showInfosBlackListModal}
        />
      )}

      {!hasOwner && (
        <ChangePersonModal
          isOpen={ownerFoundModal.isOpen}
          onClose={ownerFoundModal.onClose}
          onConfirm={() => {
            if (ownerAlreadyFound) {
              onOwnerAlreadyFound?.(ownerAlreadyFound)
              setOwnerAlreadyFound(null)
              ownerFoundModal.onClose()
              return
            }

            onOwnerAlreadyFound?.(null)
            setOwnerAlreadyFound(null)
            resetFilledForm()
            ownerFoundModal.onClose()
          }}
          title={`O proprietário encontrado pelo documento: ${watch('document')} é ${
            ownerAlreadyFound?.name
          }`}
          confirmLabel="Preencher o formulário com os dados encontrados"
        />
      )}

      {hasOwner && (
        <ChangePersonModal
          isOpen={ownerFoundModal.isOpen}
          onClose={ownerFoundModal.onClose}
          onConfirm={() => {
            if (ownerAlreadyFound) {
              onOwnerAlreadyFound?.(ownerAlreadyFound)
              setOwnerAlreadyFound(null)
              ownerFoundModal.onClose()
              return
            }

            onOwnerAlreadyFound?.(null)
            setOwnerAlreadyFound(null)
            resetFilledForm()
            ownerFoundModal.onClose()
          }}
          title="Mudanças no proprietário"
          cancelLabel="Estou editando o documento"
          confirmLabel="Quero mudar o proprietário"
          description={
            <>
              <Text>
                {ownerAlreadyFound?.name
                  ? `Realmente deseja alterar os dados do proprietário`
                  : 'Realmente deseja alterar os dados do proprietário?'}
              </Text>
              {ownerAlreadyFound && (
                <Text mt="2" color="green.500">
                  Novo proprietário: {ownerAlreadyFound?.name}
                </Text>
              )}
              <Text mt="2" fontSize="sm" opacity={0.5}>
                Para que possa ser feito o processo de mudança de proprietário, selecione umas das seguintes
                opções:
              </Text>
            </>
          }
        />
      )}
      <PisConsultationModal
        isOpen={isOpenPisConsultation}
        onClose={onClosePisConsultation}
        motherName={getValues('mother_name')}
        cpf={getValues('document')}
        birthDate={getValues('birth')}
        name={getValues('name')}
      />
      <RntrcConsultationModal
        isOpen={isOpenRntrcConsultation}
        onClose={onCloseRntrcConsultation}
        cpf={getValues('document')}
        cnpj={getValues('document')}
        rntrc={getValues('rntrc')}
        licensePlate={vehicleLicensePlate}
      />
      <Text fontSize="xs" opacity={0.5} mb="2">
        Informações de proprietário:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={[
                  { label: '', value: '' },
                  { label: 'Pessoa física', value: 'pf' },
                  { label: 'Pessoa Jurídica', value: 'pj' },
                ]}
                name="type"
                isRequired
                label="Tipo de proprietário"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="document"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                mask={ownerType === 'pj' ? '99.999.999/9999-99' : '999.999.999-99'}
                label={ownerType === 'pj' ? 'CNPJ' : 'CPF'}
                name="document"
                isError={!!error?.message}
                errorMessage={error?.message}
                isLoading={loadingOwner}
                isSearchable
                value={value}
                onChange={({ target }) => {
                  onChange(target.value)
                  searchOwner(target.value, ownerType === 'pj' ? 'pj' : 'pf')
                }}
                onSearch={() => searchOwner(value, ownerType === 'pj' ? 'pj' : 'pf')}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 5]}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                name="name"
                label={ownerType === 'pj' ? 'Razão Social' : 'Nome'}
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="rg_ie"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label={ownerType === 'pj' ? 'Inscrição Estadual' : 'RG'}
                name="rg_ie"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
                isRequired={ownerType === 'pf'}
              />
            )}
          />
        </GridItem>

        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 4, 3]}>
            <Controller
              control={control}
              name="rg_dispatcher"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  label="Órgão Expeditor RG"
                  name="rg_dispatcher"
                  isRequired
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 4, 2]}>
            <Controller
              control={control}
              name="rg_uf"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UISelect
                  options={ufOptions}
                  name="rg_uf"
                  isRequired
                  label="RG UF"
                  onChange={onChange}
                  value={value}
                  isError={!!error?.message}
                  errorMessage={error?.message}
                />
              )}
            />
          </GridItem>
        )}
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 4]}>
            <Controller
              control={control}
              name="rg_dispatch_date"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  type="date"
                  name="rg_dispatch_date"
                  isRequired
                  label="Data da Expedição"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 3]}>
            <Controller
              control={control}
              name="birth"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  type="date"
                  name="birth"
                  isRequired
                  label="Data de Nascimento"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                name="phone"
                label="Telefone Principal"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Email"
                name="email"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 6]}>
            <Controller
              control={control}
              name="mother_name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  isRequired
                  label="Nome da Mãe"
                  name="mother_name"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}

        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 6]}>
            <Controller
              control={control}
              name="father_name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  isRequired
                  label="Nome do Pai"
                  name="father_name"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
      </Grid>
      <Flex alignItems="center" gridGap={4} mb="2" mt="4">
        <Text fontSize="xs" opacity={0.5}>
          Dados da antt:
        </Text>
        <Button
          size="sm"
          onClick={() => {
            onOpenRntrcConsultation()
          }}
          colorScheme="blue"
          leftIcon={<Icon as={RiSearchLine} />}
        >
          RNTRC
        </Button>
        <Button
          size="sm"
          onClick={() => {
            onOpenPisConsultation()
          }}
          colorScheme="green"
          leftIcon={<Icon as={RiSearchLine} />}
        >
          PIS
        </Button>
      </Flex>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 3]}>
            <Controller
              control={control}
              name="pis"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  isRequired
                  mask="999999999999999999999999999999"
                  name="pis"
                  label="PIS"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="rntrc"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                mask="999999999999999999999999999999"
                name="rntrc"
                label="RNTRC"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="rntrc_type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={[
                  { label: '', value: '' },
                  { label: 'Equiparado', value: 'EQ' },
                  { label: 'ETC', value: 'E' },
                  { label: 'CTC', value: 'C' },
                  { label: 'TAC', value: 'T' },
                ]}
                label="Tipo RNTRC"
                name="rntrc_type"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>
      <Text fontSize="xs" opacity={0.5} mb="2" mt="4">
        Dados Bancários
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="bank"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelectAuto
                options={bankList}
                label="Banco"
                name="bank"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="account_type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={[
                  { label: '', value: '' },
                  { label: 'Corrente', value: 'current' },
                  { label: 'Poupança', value: 'savings' },
                  { label: 'Outros', value: 'others' },
                ]}
                name="account_type"
                label="Tipo de conta"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="agency"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Agencia"
                name="agency"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 2]}>
          <Controller
            control={control}
            name="account"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Conta"
                name="account"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>
      <Text fontSize="xs" opacity={0.5} mb="2" mt="4">
        Informações de Endereço:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="zipcode"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <UIInput
                mask="99999-999"
                label="CEP"
                name="zipcode"
                onChange={({ target }) => {
                  onChange(target.value)
                  searchAddress(target.value)
                }}
                value={value}
                isError={!!error?.message}
                errorMessage={error?.message}
                isLoading={loadingAddress}
                isSearchable
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 8]}>
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Endereço"
                type="text"
                name="address"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 1]}>
          <Controller
            control={control}
            name="address_number"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Numero"
                name="address_number"
                type="text"
                isError={!!error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="district"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Bairro"
                type="text"
                name="district"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelectAsync
                label="Cidade"
                placeholder="Cidade"
                name="city"
                loadOptions={searchCitiesByName}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={option => {
                  onChange(option)
                  setValue('city_uf', option?.uf || '')
                  clearErrors('city_uf')
                }}
                value={value}
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 2]}>
          <Controller
            control={control}
            name="city_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="UF"
                placeholder="UF"
                name="city_uf"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isRequired
                isDisabled
              />
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export const anttFormInitialValues = {
  type: 'pf',
  phone: '',
  document: '',
  zipcode: '',
  address: '',
  district: '',
  address_number: '',
  city: { label: '', value: '' },
  city_uf: '',
  mother_name: '',
  father_name: '',
  name: '',
  rg_ie: '',
  rg_uf: '',
  rg_dispatcher: '',
  rg_dispatch_date: '',
  pis: '',
  account: '',
  account_type: '',
  agency: '',
  bank: { label: '', value: '' },
  birth: '',
  email: '',
  rntrc: '',
  rntrc_type: '',
}
