import { format } from 'date-fns-tz'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { apiServer } from '../../api'
import { CheckinType, ClientType, FreightType, MotoristType } from '../../types'
import InteractionsTimelineType from '../../types/InteractionsTimelineType'
import { ResponseListApiType } from '../../types/ResponseApiType'
import TripObservationsType from '../../types/TripObservationsType'
import { getNextStatus } from './getFreights'

export interface FiltersFreightsInProgress {
  client_contact_id?: string
  motorist_id?: string
  client_id?: string
  seller_id?: string
  creator_id?: string
  origin_id?: number
  destination_id?: number
  is_monitoring?: boolean
  status?: string
  freight_number?: number | string
  initial_date?: Date
  final_date?: Date
  has_monitoring_app?: boolean
  has_monitoring_buonny?: boolean
  has_monitoring_whatsapp?: boolean
  has_monitoring_tracker_devices?: boolean
  license_plate?: string
  page?: number
  per_page?: number
}

interface MotoristTypeFormatted extends MotoristType {
  last_checkin: CheckinType
}

export interface FormattedAllFreightsInProgress extends FreightType {
  created_at: string
  motorist_id: string
  last_waypoint_date: string
  last_interaction_short_at: string
  input_collect_cargo_at: string
  last_interaction_long_at: string
  next_status: SelectOption | null
  last_interaction: InteractionsTimelineType
  client: ClientType
  motorist: MotoristTypeFormatted
  trip_observations?: TripObservationsType[]
  hasInteraction?: boolean
  remain_distance?: number
  distance_to_route?: number

  lastTripObservation?: boolean

  lastWaypointInteraction?: boolean
  lastObservationInteraction?: boolean
}

type ResponseAllFreightsInProgress = {
  total: number
  data: FormattedAllFreightsInProgress[]
}

export const freightInProgressStatus = [
  { label: '', value: '' },
  { label: 'Contratado', value: 'in_origin' },
  { label: 'Saiu para coleta', value: 'collecting' },
  { label: 'Chegou para carregar', value: 'ready' },
  { label: 'Começou a viagem', value: 'on_road' },
  { label: 'Chegou para descarregar', value: 'in_destination' },
]

export const freightInProgressTypeMonitoring = [
  { label: '', value: '' },
  { label: 'App', value: 'app' },
  { label: 'Buonny', value: 'buonny' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Dispositivos de rastreamento', value: 'tracker_devices' },
]

function hasTwoHoursPassed(lastInteraction: string) {
  if (!lastInteraction) {
    return false
  }
  // Parse the date and time of the last interaction
  const lastDateTime = new Date(lastInteraction)

  // Get the current date and time
  const currentDateTime = new Date()

  // Difference in milliseconds
  const diffMs = currentDateTime.getTime() - lastDateTime.getTime()
  // Convert the difference to hours
  const diffHours = diffMs / (1000 * 60 * 60)

  // Return true if 2 hours or more have passed, otherwise false
  return diffHours >= 2
}

export const per_page = 40
export async function fetchFreightsInProgress(
  params: FiltersFreightsInProgress,
): Promise<ResponseAllFreightsInProgress> {
  const { data: res } = await apiServer.get<ResponseListApiType<FormattedAllFreightsInProgress[]>>(
    `list-freight-in-progress`,
    { params },
  )
  const { data } = res
  const freightsInProgress = data.data.map(freight => {
    const lastObservationInteraction = hasTwoHoursPassed(freight.last_interaction?.created_at)
    const lastWaypointInteraction = hasTwoHoursPassed(freight.last_motorist_waypoint?.waypoint_date as string)

    return {
      ...freight,
      lastObservationInteraction,
      lastWaypointInteraction,
      next_status: getNextStatus(freight.status),
      last_waypoint_date: freight.last_motorist_waypoint?.waypoint_date
        ? format(new Date(freight.last_motorist_waypoint?.waypoint_date), 'dd/MM/yy HH:mm')
        : 'Não Definido',
      last_interaction_short_at: freight.last_interaction?.created_at
        ? format(new Date(freight.last_interaction?.created_at), 'dd/MM')
        : '',
      last_interaction_long_at: freight.last_interaction?.created_at
        ? format(new Date(freight.last_interaction?.created_at), 'dd/MM/yy HH:mm')
        : '',
      input_collect_cargo_at: freight.collect_cargo_at
        ? format(new Date(freight.collect_cargo_at), 'dd/MM/yy')
        : '',
      created_at: format(new Date(freight.created_at), 'dd/MM/yy'),
    }
  })

  return { total: data.total, data: freightsInProgress }
}

export function useGetFreightsInProgress(
  params: FiltersFreightsInProgress,
): UseQueryResult<ResponseAllFreightsInProgress> {
  return useQuery(['list-freight-in-progress', params], () => fetchFreightsInProgress(params), {
    retry: true,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 5,
  })
}
