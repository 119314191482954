/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

import { CreateInteractionsTimeline, ResponseApiType } from '../../../types'
import { FreightInteractionTimeline } from '../../../types/FreightInteractionsTimeline'

async function createInteractionsTimeline(data: CreateInteractionsTimeline) {
  return apiServer.post<ResponseApiType<FreightInteractionTimeline>>('/create-interaction-timeline', data)
}

export function usePostInteractionsTimeline(
  options?: UseMutationOptions<
    AxiosResponse<ResponseApiType<FreightInteractionTimeline>>,
    unknown,
    CreateInteractionsTimeline,
    unknown
  >,
) {
  return useMutation(async (data: CreateInteractionsTimeline) => createInteractionsTimeline(data), options)
}
