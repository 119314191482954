import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { FaClipboardUser } from 'react-icons/fa6'
import { GiCommercialAirplane } from 'react-icons/gi'
import {
  RiCheckLine,
  RiCloseCircleLine,
  RiEditLine,
  RiFileCopyLine,
  RiLockLine,
  RiMapPinLine,
  RiRoadMapLine,
  RiTruckLine,
} from 'react-icons/ri'
import { TbRoute2 } from 'react-icons/tb'
import { Ability } from '../../../../../components/ability'
import { AccordionFreightPhases } from '../../../../../components/accordionFreightPhases/AccordionFreightPhases'
import { AlertCardCostRiskAnalysis } from '../../../../../components/CostRiskAnalysis/AlertCardCostRiskAnalysis'
import { FreightMap } from '../../../../../components/map/FreightMap'
import { toastError } from '../../../../../config/error/toastError'
import { useGlobalVariables } from '../../../../../contexts/GlobalVariables'
import { useIsBlockEntity } from '../../../../../services/endpoints/blocked-entities/isBlockEntity'
import { useGetMapDirections } from '../../../../../services/endpoints/freights/getMapDirections'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { useHasPendencies } from '../../../../../services/endpoints/freights/hasPendencies'
import { useCheckCostRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/cost-risk-analysis/checkCostRiskAnalysis'
import {
  GetRoutePointsReturn,
  useGetRoutePoints,
} from '../../../../../services/endpoints/truckpad/find-route-points'
import { FreightType, VehicleType } from '../../../../../services/types'
import { copyToClipboard } from '../../../../../services/utils/copyToClipboard'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import {
  changeStatusMonitoringWhatsAppByZApiCron,
  changeWhatsAppSendingIntervalByZApi,
  sendingInitialRequestMonitoringWhatsAppByZApi,
  sendingRequestMonitoringWhatsAppByZApi,
  setActionFreightType,
  setDisabledBtnSendingMsgWhatsAppByZApi,
  setInitialBtnWhatsAppByZApi,
  setStateTogglePermissionWhatsAppByZApi,
} from '../../../../../store/slices/freightSlice'
import { setDriverLicenseImg, setIsNewImage } from '../../../../../store/slices/ocrCnhSlice'
import { Route, RoutePlannerModal } from '../../../../pricing/route-planner/show/modals/RoutePlannerModal'
import { DisableFreightModal } from '../../../edit/DisableFreightModal'
import { EditOrDuplicateFreightModal } from '../../../edit/EditOrDuplicateFreightModal'
import { EnableFreightModal } from '../../../edit/EnableFreightModal'
import { Timeline } from '../monitoring-timeline/Timeline'
import { EvaluateMotoristModal } from '../selected-motorist/EvaluateMotoristModal'
import { SelectNextStatusModal } from '../selected-motorist/SelectNextStatusModal'
import { UpdateCheckinModal } from '../selected-motorist/UpdateCheckinModal'

interface FreightInfoProps {
  freight: FormattedOneFreight
  bg: string
}

export interface FreightPendenciesResult {
  motorist: string[]
  vehicle: string[]
  vehicleOwner: string[] | null
  trailer: string[]
  trailerOwner: string[] | null
}

const encodeLocation = (location: any) => {
  const locationParams = {
    lat: location.lat,
    lng: location.lng,
    uf: location.state.uf,
    label: location.name,
    country: 'BRA',
    cityName: location.name,
  }
  return encodeURIComponent(JSON.stringify(locationParams))
}

export const buildRoutePlannerUrl = (freight: FreightType, vehicle?: VehicleType): string => {
  const originParams = encodeLocation(freight.origin)
  const destinationParams = encodeLocation(freight.destination)
  return (
    `/pricing/route-planner?freight_id=${freight?.id}` +
    `&freight_number=${freight?.freight_number}` +
    `&origin=${originParams}` +
    `&destination=${destinationParams}` +
    `&freight_status=${freight?.status}` +
    `&vehicle_plate=${vehicle?.license_plate || ''}` +
    `&isFreightPage=true`
  )
}

export function FreightInfo({ freight, bg }: FreightInfoProps): JSX.Element {
  const { REACT_APP_BASE_WEB } = process.env
  const toast = useToast()
  const dispatch = useAppDispatch()
  const {
    statusHistory,
    actionFreightType,
    isLoading,
    waypointsZApi,
    initialBtnWppMonitoringDisabled,
    initialResStatusWppMonitoring,
    stateTogglePermission,
    btnSendingMsgToMotoristDisabled,
  } = useAppSelector(state => state.freightSlice)

  const { riskAnalysis } = useAppSelector(state => state.riskAnalysisSlice)
  const { setPageTitle } = useGlobalVariables()
  const {
    data: isBlockedEntity,
    isError,
    error,
  } = useIsBlockEntity({
    freight_id: freight.id,
  })

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error, toast])

  const {
    isOpen: isOpenEvaluateMotorist,
    onClose: onCloseEvaluateMotorist,
    onOpen: onOpenEvaluateMotorist,
  } = useDisclosure()

  const {
    isOpen: isDisableFreightModalOpen,
    onOpen: onDisableFreightModalOpen,
    onClose: onDisableFreightModalClose,
  } = useDisclosure()

  const {
    isOpen: isEnableFreightModalOpen,
    onOpen: onEnableFreightModalOpen,
    onClose: onEnableFreightModalClose,
  } = useDisclosure()

  // const {
  //   onOpen: onOpenCreateManualMonitoring,
  //   onClose: onCloseCreateManualMonitoring,
  //   isOpen: isOpenCreateManualMonitoring,
  // } = useDisclosure()

  const {
    isOpen: isFreightFormModalOpen,
    onOpen: onFreightFormModalOpen,
    onClose: onFreightFormModalClose,
  } = useDisclosure()

  const {
    isOpen: isUpdateCheckinOpen,
    onClose: onUpdateCheckinClose,
    onOpen: onUpdatecheckinOpen,
  } = useDisclosure()

  const { isOpen: isOpenTimeline, onClose: onCloseTimeline, onOpen: onOpenTimeline } = useDisclosure()

  const {
    isOpen: isOpenRoutePlanner,
    onOpen: onOpenRoutePlanner,
    onClose: onCloseRoutePlanner,
  } = useDisclosure()

  const { data: routePointsData } = useGetRoutePoints({ freight_id: freight?.id })

  function normalizeRoutePoints(data: GetRoutePointsReturn[]): Route[][] {
    return data.map(point => [
      {
        points: [],
        tolls_place: [],
        route_points: [[point.latitude, point.longitude]],
        distance: 0,
        distance_unit: 'km',
        duration: 0,
        duration_unit: 'minutes',
        tolls_amount: 0,
        tolls_gross_amount: 0,
        tolls_count: 0,
        route_provider: 'Unknown',
        travel_type: 'Unknown',
        cached: null,
      },
    ])
  }

  const { data: checkCostRiskAnalysis } = useCheckCostRiskAnalysis({
    freight_id: freight?.id,
  })

  if (freight) {
    setPageTitle(
      `Frete #${freight?.freight_number} |
    ${freight?.client?.nickname} |
    ${freight?.origin_city} / ${freight?.destination_city} |
    SpotX Admin`,
    )
  }

  const getMapDirections = useGetMapDirections(freight?.origin, freight?.destination)

  function generatePasswordClientPage(): string {
    if (freight.freight_number && freight.created_at_default) {
      const freightCreatedAt = new Date(freight.created_at_default)
      const calc = freight.freight_number * (freightCreatedAt.getDay() + 1)
      return String(calc)
    }
    return '12345'
  }

  function copyMotoristData(): void {
    if (freight.motorist && freight.motorist.cpf && freight.vehicle) {
      copyToClipboard(`${freight.motorist.name.toLocaleUpperCase()}
CPF: ${formatCPFAndCNPJ(freight.motorist.cpf)}
${freight.vehicle.vehicle_category.name.toLocaleUpperCase()} ${freight.vehicle.vehicle_body.name.toLocaleUpperCase()}
CAVALO: ${freight.vehicle.license_plate}
${freight.trailer ? `CARRETA: ${freight.trailer.license_plate}` : ''}`)
    }
  }

  useEffect(() => {
    dispatch(setDriverLicenseImg(''))
    dispatch(setIsNewImage(false))
    if (freight?.id && freight?.motorist_id) {
      dispatch(setStateTogglePermissionWhatsAppByZApi(Boolean(freight?.can_monitoring_whatsapp)))
    }
  }, [dispatch, freight?.id, freight?.motorist_id, freight?.can_monitoring_whatsapp])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setInitialBtnWhatsAppByZApi(false))
    }, 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    waypointsZApi,
    initialBtnWppMonitoringDisabled,
    initialResStatusWppMonitoring,
    dispatch,
    freight?.status,
  ])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setDisabledBtnSendingMsgWhatsAppByZApi(false))
    }, 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, btnSendingMsgToMotoristDisabled])

  const { data: has_pendencies, isLoading: isLoadingPendencies } = useHasPendencies({
    freight_id: freight?.id,
    motorist_id: String(freight?.motorist_id),
    vehicle_id: String(freight?.vehicle_id),
  })

  return (
    <>
      {checkCostRiskAnalysis && checkCostRiskAnalysis.length > 0 && (
        <AlertCardCostRiskAnalysis
          risk_manager={checkCostRiskAnalysis[0].cost_risk_analysis_risk_manager}
          data={checkCostRiskAnalysis}
        />
      )}

      {freight && (
        <>
          <Flex justifyContent="space-between" alignItems="baseline">
            <Flex direction={['column', 'row']} align="center" gridGap={5} mb={3}>
              <Text fontSize={['lg', 'x-large']} fontWeight="normal">
                Frete #{freight?.freight_number}
              </Text>
              {freight.type === 'aerial' && (
                <Tooltip label="Frete Aéreo" hasArrow placement="top">
                  <Flex align="center" cursor="help">
                    <GiCommercialAirplane size={24} />
                  </Flex>
                </Tooltip>
              )}
            </Flex>

            <Stack direction={['column', 'row']} mb="2">
              {freight?.status === 'finished' && (
                <Flex gridGap="2" alignItems="center" fontSize={['sm', 'md', 'lg']}>
                  <Tooltip label="Copiar link">
                    <Flex>
                      <Icon
                        cursor="pointer"
                        as={RiFileCopyLine}
                        onClick={() => {
                          copyToClipboard(
                            `${REACT_APP_BASE_WEB}/client-feedback/${freight.id}?short-feedback=true`,
                          )
                          toast({
                            title: 'Copiado com sucesso!',
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                            duration: 1000,
                          })
                        }}
                      />
                    </Flex>
                  </Tooltip>
                  <Link href={`/client-feedback/${freight.id}?short-feedback=true`}>Feedback do cliente</Link>

                  {/* <Tooltip label="Copiar link">
                    <Flex>
                      <Icon
                        cursor="pointer"
                        as={RiFileCopyLine}
                        onClick={() => {
                          copyToClipboard(
                            `${REACT_APP_BASE_WEB}/client-feedback/${freight.id}`,
                          );
                          toast({
                            title: 'Copiado com sucesso!',
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                            duration: 1000,
                          });
                        }}
                      />
                    </Flex>
                  </Tooltip>
                  <Link href={`/client-feedback/${freight.id}`}>
                    Feedback do cliente
                  </Link> */}
                </Flex>
              )}

              {freight && (
                <Flex gridGap="2" alignItems="center" fontSize={['sm', 'md', 'lg']}>
                  {freight.quotation_id && (
                    <>
                      <Tooltip label="Copiar link">
                        <Flex>
                          <Icon
                            cursor="pointer"
                            as={RiFileCopyLine}
                            onClick={() => {
                              copyToClipboard(`${REACT_APP_BASE_WEB}/${freight.quotation_id}`)
                              toast({
                                title: 'Copiado com sucesso!',
                                status: 'success',
                                position: 'top-right',
                                isClosable: true,
                                duration: 1000,
                              })
                            }}
                          />
                        </Flex>
                      </Tooltip>
                      <Link href={`${freight.quotation_id}`}>Link da cotação</Link>
                    </>
                  )}

                  {freight.motorist && freight.vehicle && (
                    <Tooltip label="Copiar dados básicos do motorista">
                      <Flex>
                        <Icon
                          cursor="pointer"
                          as={FaClipboardUser}
                          onClick={() => {
                            copyMotoristData()
                            toast({
                              title: 'Copiado com sucesso!',
                              status: 'success',
                              position: 'top-right',
                              isClosable: true,
                              duration: 1000,
                            })
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  )}

                  {freight.has_monitoring_buonny && (
                    <Tooltip label="Copiar senha">
                      <Flex>
                        <Icon
                          cursor="pointer"
                          as={RiLockLine}
                          onClick={() => {
                            copyToClipboard(generatePasswordClientPage())
                            toast({
                              title: 'Copiado com sucesso!',
                              status: 'success',
                              position: 'top-right',
                              isClosable: true,
                              duration: 1000,
                            })
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  )}
                  <Tooltip label="Copiar link">
                    <Flex>
                      <Icon
                        cursor="pointer"
                        as={RiFileCopyLine}
                        onClick={() => {
                          copyToClipboard(`${REACT_APP_BASE_WEB}/tracking/${freight.id}`)
                          toast({
                            title: 'Copiado com sucesso!',
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                            duration: 1000,
                          })
                        }}
                      />
                    </Flex>
                  </Tooltip>
                  <Link href={`/tracking/${freight.id}`}>Link do cliente</Link>
                </Flex>
              )}
            </Stack>
          </Flex>

          <Flex
            justify="space-between"
            direction={['column', 'column', 'row']}
            gridGap="4"
            alignItems="center"
            mb={4}
          >
            <Flex justify="space-between" gridGap="2" direction={['column', 'column', 'row']}>
              <WrapItem alignItems="center">
                <Tag size="lg" borderRadius="full" backgroundColor="whiteAlpha.900" color="gray.700">
                  <TagLeftIcon fontSize={['xx-small', 'sm', 'sm']} as={RiMapPinLine} />
                  <TagLabel mr="3" fontSize={['xx-small', 'sm', 'sm']}>
                    {freight.origin_city.substring(0, 20)}
                  </TagLabel>
                  {'>'}
                  <TagLeftIcon as={RiRoadMapLine} ml="2" />
                  <TagLabel fontSize={['xx-small', 'sm', 'sm']}>
                    {freight.destination_city.substring(0, 20)}
                  </TagLabel>
                  <Text textAlign="center" direction={['row']} fontSize={['xx-small', 'sm', 'sm']} ml="2">
                    ({getMapDirections.data?.metrics.distance})
                  </Text>
                </Tag>
              </WrapItem>
            </Flex>

            <SelectNextStatusModal />

            <Stack direction={['column', 'row', 'row']} spacing="2" alignItems="center">
              {freight?.calculation_ref && (
                <Tooltip label="Ir para a calculadora">
                  <Text as={Link} href={freight.calculation_ref}>
                    <Icon as={AiOutlineCalculator} color="orange" fontSize="3xl" mr="1" />
                  </Text>
                </Tooltip>
              )}

              <>
                {!['finished', 'disabled'].includes(String(freight?.status)) && (
                  <Button
                    size="sm"
                    colorScheme="red"
                    leftIcon={<Icon as={RiCloseCircleLine} />}
                    onClick={onDisableFreightModalOpen}
                  >
                    Desativar
                  </Button>
                )}

                {freight?.status === 'disabled' && (
                  <Button
                    size="sm"
                    colorScheme="green"
                    leftIcon={<Icon as={RiCheckLine} />}
                    onClick={onEnableFreightModalOpen}
                  >
                    Reativar
                  </Button>
                )}

                <EnableFreightModal
                  freight_id={freight.id}
                  isOpen={isEnableFreightModalOpen}
                  onClose={() => {
                    onEnableFreightModalClose()
                  }}
                />
              </>

              <Ability module="operation" action="create-freight">
                <Button
                  size="sm"
                  colorScheme="blue"
                  leftIcon={<Icon as={RiFileCopyLine} />}
                  onClick={() => {
                    onFreightFormModalOpen()
                    dispatch(setActionFreightType('duplicate'))
                  }}
                >
                  Duplicar
                </Button>
              </Ability>

              <Ability module="operation" action="update-freight">
                <Button
                  size="sm"
                  colorScheme="yellow"
                  isDisabled={isBlockedEntity}
                  leftIcon={<Icon as={RiEditLine} />}
                  onClick={() => {
                    onFreightFormModalOpen()
                    dispatch(setActionFreightType('edit'))
                  }}
                >
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={isBlockedEntity && 'Frete em analise! Edição bloqueada!'}
                  >
                    Editar
                  </Tooltip>
                </Button>
              </Ability>

              <Button
                size="sm"
                colorScheme="green"
                isDisabled={isBlockedEntity}
                leftIcon={<Icon as={TbRoute2} />}
                onClick={() => {
                  if (routePointsData && routePointsData.length > 0) {
                    onOpenRoutePlanner()
                  } else {
                    window.location.href = buildRoutePlannerUrl(freight, freight.vehicle)
                  }
                }}
              >
                <Tooltip
                  hasArrow
                  placement="top"
                  label={isBlockedEntity ? 'Frete em analise! Edição bloqueada!' : ''}
                >
                  Rotas
                </Tooltip>
              </Button>
            </Stack>
          </Flex>

          {/* botoes sao até aqui */}

          {freight?.status === 'disabled' && (
            <Alert status="error" colorScheme="red" flexDirection="column" borderRadius="md" my="4">
              <Flex mb="3">
                <AlertIcon />
                <Heading fontSize="lg">Frete desativado</Heading>
              </Flex>
              {statusHistory && statusHistory.length > 0 && (
                <SimpleGrid gridGap="5" spacingX="-19" columns={2}>
                  <Flex gridGap="1">
                    <Text color="gray.500">Desativado em:</Text>
                    <Text>{statusHistory[0]?.created_at}</Text>
                  </Flex>
                  <Flex gridGap="1">
                    <Text color="gray.500">Desativado por:</Text>
                    <Text>{statusHistory[0]?.user?.name}</Text>
                  </Flex>
                  <Flex gridGap="1">
                    <Text color="gray.500">Motivo:</Text>
                    <Text>{statusHistory[0]?.disabled_reason}</Text>
                  </Flex>
                  <Flex gridGap="1">
                    <Text color="gray.500">Descrição:</Text>
                    <Text>{statusHistory[0]?.description}</Text>
                  </Flex>
                </SimpleGrid>
              )}
            </Alert>
          )}

          {freight?.motorist?.id && (
            <Flex gridGap={2} my="4" direction={['column']}>
              {isLoadingPendencies ? (
                <Flex justify="center" my="3" gridGap={3}>
                  Checando pendências... <Spinner size="md" />
                </Flex>
              ) : has_pendencies ? (
                <Alert
                  variant="subtle"
                  status="warning"
                  borderRadius="md"
                  justifyContent="space-between"
                  flexDirection={['column', 'row']}
                  gridGap="2"
                >
                  <Flex direction={['column', 'row']} justify="center">
                    <AlertIcon />
                    <AlertTitle mr={2}>O Motorista ou veículo possuem pendências!</AlertTitle>
                    <AlertDescription>Por favor, complete as informações necessárias.</AlertDescription>
                  </Flex>
                  <Button
                    colorScheme="orange"
                    ml="4"
                    minW={150}
                    size="sm"
                    onClick={() => {
                      window.location.href = `/freights/show/${freight.id}/pendencies?motorist_id=${freight.motorist_id}&vehicle_id=${freight.vehicle_id}&integration=datamex`
                    }}
                  >
                    Resolver pendências
                  </Button>
                </Alert>
              ) : (
                <Alert variant="subtle" status="success" borderRadius="md" justifyContent="space-between">
                  <Flex alignItems="center">
                    <AlertIcon />
                    <AlertTitle mr={2} fontSize="lg">
                      Parabéns. Este frete não possui pendências.
                    </AlertTitle>
                  </Flex>
                  <AlertDescription>
                    <Button
                      variant="ghost"
                      colorScheme="green"
                      ml="4"
                      size="sm"
                      leftIcon={<Icon as={RiCheckLine} fontSize="x-large" />}
                      onClick={() => {
                        window.location.href = `/freights/show/${freight.id}/pendencies?motorist_id=${freight.motorist_id}&vehicle_id=${freight.vehicle_id}&integration=datamex`
                      }}
                    >
                      Verificar
                    </Button>
                  </AlertDescription>
                </Alert>
              )}

              {/* <Alert variant="subtle" status="info" borderRadius="md" justifyContent="space-between">
                <Flex alignItems="center">
                  <AlertIcon />
                  <AlertTitle mr={2}>Atualize o monitoramento de modo manual</AlertTitle>
                  <AlertDescription>
                    Atualize a localização do motorista de modo manual(Telefone ou Dispositivos) clicando no
                    botão.
                  </AlertDescription>
                </Flex>
                <Tooltip label="O motorista irá receber uma mensagem solicitando e informando que ele precisa enviar a localização dele via WhatsApp.">
                  <Popover
                    closeOnBlur
                    onClose={onCloseCreateManualMonitoring}
                    isOpen={isOpenCreateManualMonitoring}
                    onOpen={onOpenCreateManualMonitoring}
                  >
                    <PopoverTrigger>
                      <Button colorScheme="orange" ml="4" size="sm" minW={150}>
                        Informar localização
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverHeader>Escolha a cidade abaixo:</PopoverHeader>
                        <PopoverCloseButton />
                        <PopoverBody>
                          <CreateManualMonitoring freight={freight} close={onCloseCreateManualMonitoring} />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Tooltip>
              </Alert> */}

              {freight.status !== 'delivered' && freight.has_monitoring_whatsapp === false && (
                <Alert variant="subtle" status="info" borderRadius="md" justifyContent="space-between">
                  <Flex alignItems="center">
                    <AlertIcon />
                    <AlertTitle mr={2}>Monitoramento via WhatsApp!</AlertTitle>
                    <AlertDescription>
                      Inicie o monitoramento via WhatsApp com o motorista (O monitoramento será feito pelo
                      WhatsApp do Bot).
                    </AlertDescription>
                  </Flex>
                  <Tooltip label="O motorista irá receber uma mensagem solicitando e informando que ele precisa enviar a localização dele via WhatsApp.">
                    <Button
                      disabled={initialBtnWppMonitoringDisabled}
                      colorScheme="orange"
                      ml="4"
                      size="sm"
                      minW={150}
                      onClick={() => {
                        dispatch(setInitialBtnWhatsAppByZApi(true))
                        dispatch(sendingInitialRequestMonitoringWhatsAppByZApi(freight?.id))
                        toast({
                          title: 'Sucesso!',
                          description: 'Foi enviado com sucesso a mensagem para o motorista.',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }}
                    >
                      {initialBtnWppMonitoringDisabled ? 'Por favor, espere...' : 'Iniciar monitoramento'}
                    </Button>
                  </Tooltip>
                </Alert>
              )}

              {freight.motorist_id && riskAnalysis && riskAnalysis.status === 'A Checar' && (
                <Alert variant="subtle" status="warning" borderRadius="md" justifyContent="space-between">
                  <Flex alignItems="center">
                    <AlertIcon />
                    <AlertTitle mr={2}>
                      Atenção! Existem pendências de checagem para o motorista vinculado, aguarde o processo
                      de análise finalizar para emitir os documentos!
                    </AlertTitle>
                  </Flex>
                </Alert>
              )}
            </Flex>
          )}
          {/* timeline */}
          {freight && (
            <Flex
              bg={bg}
              p="2"
              borderRadius="8"
              shadow="md"
              mb="8"
              justifyContent="space-between"
              direction={['column', 'column', 'row']}
            >
              {freight.status !== 'pending' && (
                <Box w={['100%', '100%', '850px']} p="3" maxH="500px" overflowY="scroll">
                  <Heading size="md" fontWeight="normal" mb="2">
                    Status do frete
                  </Heading>
                  <HStack alignItems="center" justifyContent="flex-end" mb="8">
                    {freight.status === 'on_road' && (
                      <>
                        <Button
                          size="xs"
                          mx="2"
                          h="6"
                          colorScheme="green"
                          leftIcon={<Icon as={RiMapPinLine} />}
                          onClick={onUpdatecheckinOpen}
                        >
                          Atualizar checkin
                        </Button>
                        <UpdateCheckinModal
                          isOpen={isUpdateCheckinOpen}
                          onClose={() => {
                            onUpdateCheckinClose()
                          }}
                          motorist_id={String(freight.motorist?.id)}
                        />
                      </>
                    )}
                    <Tag
                      size="md"
                      px="5"
                      borderRadius="full"
                      mx="2"
                      bg={freight?.next_status ? 'green.100' : 'red.100'}
                    >
                      <TagLeftIcon as={RiTruckLine} color="gray.700" />
                      <TagLabel color="gray.700" fontSize={['xs', 'sm', 'md']}>
                        {freight.formatted_status}
                      </TagLabel>
                    </Tag>
                  </HStack>
                  <Divider mb="2" />

                  <AccordionFreightPhases />

                  {freight.motorist_id && freight.vehicle_id && freight.has_monitoring_app && (
                    <Flex alignItems="center" justify="flex-end" mt="8">
                      <Button colorScheme="orange" onClick={onOpenTimeline} size="sm">
                        Ver timeline de monitoramento
                      </Button>
                      <Timeline freight={freight} isOpen={isOpenTimeline} onClose={onCloseTimeline} />
                    </Flex>
                  )}
                </Box>
              )}
              <FreightMap freight_id={freight.id} type="app" />
            </Flex>
          )}

          {!!freight.has_monitoring_buonny && freight.vehicle_id && (
            <Flex
              bg={bg}
              p="2"
              borderRadius="8"
              shadow="md"
              mb="8"
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Heading size="md" fontWeight="normal" mb="4">
                Monitoramento Buonny
              </Heading>
              {isLoading ? (
                <Spinner />
              ) : (
                <Flex p="2" py="4" rounded="md" h="400px" bg={bg}>
                  <FreightMap freight_id={freight.id} type="buonny" />
                </Flex>
              )}
            </Flex>
          )}

          {freight.has_monitoring_whatsapp && (
            <Flex
              bg={bg}
              p="2"
              borderRadius="8"
              shadow="md"
              mb="8"
              alignItems="center"
              justifyContent="center"
              justify="space-between"
              align="center"
              direction={['column', 'column', 'column']}
              gridGap={2}
            >
              <Heading size="md" mb={10} fontWeight="normal">
                Monitoramento WhatsApp
              </Heading>
              <Flex alignItems="center" justifyContent="center">
                <Flex alignItems="center" justifyContent="center" direction="row">
                  <FormLabel htmlFor="isToggle">Envio automático?</FormLabel>
                  <Switch
                    id="isToggle"
                    isChecked={stateTogglePermission}
                    defaultChecked={stateTogglePermission}
                    checked={stateTogglePermission}
                    onChange={() => {
                      dispatch(changeStatusMonitoringWhatsAppByZApiCron(freight.id, stateTogglePermission))
                      toast({
                        title: 'Alteração feita com sucesso!',
                        description:
                          !stateTogglePermission === true
                            ? 'A partir de agora esse motorista receberá mensagens automáticas.'
                            : 'O motorista NÃO receberá mais mensagens automáticas!',
                        status: 'success',
                        position: 'top-right',
                        isClosable: true,
                        duration: 10000,
                      })
                    }}
                  />
                </Flex>
                <Center height="20px" ml={10} mr={10}>
                  <Divider orientation="vertical" />
                </Center>
                <Flex alignItems="center" justifyContent="center" direction="column">
                  <FormLabel htmlFor="isSelectedInterval">Intervalo para o envio de mensagem:</FormLabel>
                  <Select
                    id="isSelectedInterval"
                    placeholder="Selecione um intervalo"
                    defaultValue={freight.whatsapp_notification_interval ?? 2}
                    onChange={e => {
                      if (e.target.value !== '') {
                        dispatch(changeWhatsAppSendingIntervalByZApi(freight.id, Number(e.target.value)))
                        toast({
                          title: 'Alteração feita com sucesso!',
                          description: `A partir de agora esse motorista receberá mensagens automáticas no intervalo de ${e.target.value} Horas.`,
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                          duration: 5000,
                        })
                      }
                    }}
                  >
                    <option value={2}>2 Horas</option>
                    <option value={4}>4 Horas</option>
                    <option value={6}>6 Horas</option>
                    <option value={8}>8 Horas</option>
                  </Select>
                </Flex>
                <Center height="20px" ml={10} mr={10}>
                  <Divider orientation="vertical" />
                </Center>
                <Flex alignItems="center" justifyContent="center" direction="column">
                  <FormLabel htmlFor="isSelectedInterval">Solicite o envio manualmente:</FormLabel>
                  <Tooltip label="Envie uma mensagem manualmente para o motorista que está fazendo um frete com monitoramento por WhatsApp.">
                    <Button
                      disabled={btnSendingMsgToMotoristDisabled}
                      isLoading={isLoading}
                      colorScheme="green"
                      ml="4"
                      size="sm"
                      width="200px"
                      onClick={() => {
                        dispatch(sendingRequestMonitoringWhatsAppByZApi(freight.id))
                        dispatch(setDisabledBtnSendingMsgWhatsAppByZApi(true))

                        toast({
                          title: 'Sucesso!',
                          description: 'Mensagem enviada com sucesso para o WhatsApp do motorista.',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }}
                    >
                      {btnSendingMsgToMotoristDisabled ? 'Por favor, espere...' : 'Solicitar localização'}
                    </Button>
                  </Tooltip>
                </Flex>
              </Flex>
              <Divider orientation="horizontal" />
              <FreightMap freight_id={freight.id} type="whatsapp" />
            </Flex>
          )}

          <EditOrDuplicateFreightModal
            type={actionFreightType}
            freight_id={freight.id}
            isOpen={isFreightFormModalOpen}
            onClose={() => {
              onFreightFormModalClose()
            }}
          />

          <DisableFreightModal
            freight_ids={[freight.id]}
            isOpen={isDisableFreightModalOpen}
            onClose={() => {
              onDisableFreightModalClose()
              if (freight.motorist_id && !freight.rate) {
                onOpenEvaluateMotorist()
              }
            }}
          />

          <RoutePlannerModal
            isOpen={isOpenRoutePlanner}
            onClose={onCloseRoutePlanner}
            routes={normalizeRoutePoints(routePointsData || []) || []}
            freight_id={freight.id}
            vehicle_license_plate={freight.vehicle?.license_plate}
            origin={freight.origin.name}
            destination={freight.destination.name}
            freightTollValue={
              routePointsData && routePointsData[0] ? routePointsData[0].toll_value : undefined
            }
            freight_distance={freight.distance ? String(freight.distance) : undefined}
            isPageFreight
          />

          <EvaluateMotoristModal isOpen={isOpenEvaluateMotorist} onClose={onCloseEvaluateMotorist} />
        </>
      )}
    </>
  )
}
