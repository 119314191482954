/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import { FreightOccurrencesType } from '../../../types/FreightOccurrencesType'

export interface ICreateFreightOccurrences {
  freight_id: string
  description: string
  type: string
  attachments?: FileList
}

async function postFreightOccurrences(data: ICreateFreightOccurrences) {
  return apiServer.post<ResponseApiType<FreightOccurrencesType>>(`/freight-occurrences`, {
    ...data,
  })
}

export function useCreateFreightOccurrences(
  options?: UseMutationOptions<
    AxiosResponse<ResponseApiType<FreightOccurrencesType>>,
    unknown,
    ICreateFreightOccurrences,
    unknown
  >,
) {
  return useMutation(async (data: ICreateFreightOccurrences) => postFreightOccurrences(data), options)
}
