import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'

export interface CreateFreightAttachmentsInput {
  freight_id: string
  attachments: FileList | File
  freight_occurrences_id?: string
  description?: string
  freight_status?: string
  name?: string
  type?: string
  document?: string
  delivery_receipt_at?: Date
}

async function createAttachments(data: CreateFreightAttachmentsInput): Promise<void> {
  const formData = new FormData()
  formData.append('freight_id', data.freight_id)
  if (data.attachments instanceof FileList) {
    for (let i = 0; i < data.attachments.length; i += 1) {
      formData.append('attachments', data.attachments[i])
    }
  } else {
    formData.append('attachments', data.attachments)
  }
  if (data.type) {
    formData.append('type', data.type)
  } else {
    formData.append('type', 'default')
  }
  if (data.freight_occurrences_id) formData.append('freight_occurrences_id', data.freight_occurrences_id)
  if (data.description) formData.append('description', data.description)
  if (data.document) formData.append('document', data.document)
  if (data.freight_status) formData.append('freight_status', data.freight_status)
  if (data.name) formData.append('name', data.name)
  if (data.delivery_receipt_at) formData.append('delivery_receipt_at', data.delivery_receipt_at.toISOString())

  await apiServer.post<ResponseApiType<void>>('/freights/attachments', formData)
}

export function useCreateAttachments(
  options?: UseMutationOptions<void, unknown, CreateFreightAttachmentsInput, unknown>,
): UseMutationResult<void, unknown, CreateFreightAttachmentsInput, unknown> {
  return useMutation(async (data: CreateFreightAttachmentsInput) => createAttachments(data), options)
}
