/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import { FreightOccurrencesType } from '../../../types/FreightOccurrencesType'

export interface IDeleteFreightOccurrences {
  id: string
}

async function deleteFreightOccurrences(input: IDeleteFreightOccurrences) {
  return apiServer.delete<ResponseApiType<FreightOccurrencesType>>(`/freight-occurrences/${input.id}`)
}

export function useDeleteFreightOccurrences(
  options?: UseMutationOptions<AxiosResponse, unknown, IDeleteFreightOccurrences, unknown>,
) {
  return useMutation(async (input: IDeleteFreightOccurrences) => deleteFreightOccurrences(input), options)
}
