import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { startOfMonth } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import ptLocale from 'date-fns/locale/pt-BR'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BiSync } from 'react-icons/bi'
import { FaClipboardList } from 'react-icons/fa'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Ability } from '../../../components/ability'
import { ButtonsActions } from '../../../components/datamex/list-ctes/ButtonsActions'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import Filter from '../../../components/UI/Filter'
import { PageHeader } from '../../../components/UI/PageHeader'
import { toastError } from '../../../config/error/toastError'
import { useAuth } from '../../../contexts/AuthContext'
import FixOverflow from '../../../hooks/fixOverflow'
import { useAbility } from '../../../hooks/useAbility'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { FetchCteReportParams, fetchCtes, useGetCtes } from '../../../services/endpoints/datamex/getCtes'
import { useGetTotalCteValues } from '../../../services/endpoints/datamex/getTotalCteValues'
import { useUpdateCteEvents } from '../../../services/endpoints/datamex/updateCteEvents'
import parseCurrency from '../../../services/utils/parseCurrency'
import { boxWidth, displayAdaptable } from '../../../services/utils/tableAdaptable'
import { FiltersCtes } from '../FiltersCtes'

export interface FetchCteReportParamsFormatted {
  key?: string
  nct?: string
  serie?: number
  emit_date_ini?: string
  emit_date_final?: string
  emitter?: string
  taker?: string
  page?: number
  origin_name?: AutocompleteOption
  destination_name?: AutocompleteOption
  per_page?: number
  unpaged?: boolean
  status?: string
  type?: string
  operator?: string
  seller?: SelectOption
  analist?: SelectOption
  payment_status?: string
  initial_date: string
  final_date: string
  freight_number?: string
}

export const filterCte = yup.object().shape({
  key: yup.string(),
  initial_date: yup.string(),
  final_date: yup.string(),
  status: yup.string(),
  type: yup.string(),
  origin_name: yup.object().shape({}),
  destination_name: yup.object().shape({}),
})

export function ListCtes(): JSX.Element {
  const abilityListCte = useAbility('operation', 'list-ctes')
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    key?: string
    nct?: string
    serie?: string
    page?: string
    emitter?: string
    taker?: string
    operator?: string
    type?: string
    status?: string
    payment_status?: string
    emit_date_ini?: string
    emit_date_final?: string
    freight_number?: string
  }>()

  FixOverflow()
  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })
  const initialDate = () => {
    const now = startOfMonth(new Date())
    return format(now, 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const { onFilterBySellerLogged, getSellerIdToListFreights } = useAuth()
  const toast = useToast()

  const [filters, setFilters] = useState<FetchCteReportParams>({
    emit_date_ini: `${initialDate()}`,
    emit_date_final: `${finalDate()}`,
  })

  const bg = useColorModeValue('white', 'gray.800')
  const {
    mutateAsync: handleUpdateCtesEvents,
    isError,
    isLoading: isLoadingUpdateCtesEvents,
  } = useUpdateCteEvents()

  const {
    data: ctes,
    isLoading,
    error: errorGetCtes,
    isError: isErrorGetCtes,
  } = useGetCtes({
    ...filters,
    seller: onFilterBySellerLogged()?.value,
    page: filters.page,
  })

  useEffect(() => {
    if (isErrorGetCtes) {
      toastError({ toast, error: errorGetCtes })
    }
  }, [isErrorGetCtes, errorGetCtes, toast])

  const {
    data: cteValues,
    isLoading: isLoadingValues,
    error: errorGetTotalCteValues,
    isError: isErrorGetTotalCteValues,
  } = useGetTotalCteValues({
    ...filters,
    seller: onFilterBySellerLogged()?.label,
  })

  useEffect(() => {
    if (isErrorGetTotalCteValues) {
      toastError({ toast, error: errorGetTotalCteValues })
    }
  }, [isErrorGetTotalCteValues, errorGetTotalCteValues, toast])

  const { setValue, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(filterCte),
  })

  const { errors, isSubmitting } = formState

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilter: SubmitHandler<FetchCteReportParamsFormatted> = data => {
    setFilters({
      key: data.key,
      nct: data.nct,
      serie: data.serie,
      emit_date_ini: data.emit_date_ini || data.initial_date,
      emit_date_final: data.emit_date_final || data.final_date,
      status: data.status,
      type: data.type,
      emitter: data.emitter,
      taker: data.taker,
      origin_name: data.origin_name?.label,
      destination_name: data.destination_name?.label,
      freight_number: data.freight_number,
      operator: data.operator,
      payment_status: data.payment_status,
      page: data.page || 1,
      analist_id: data.analist?.value,
    })

    updateQueryParams({
      key: data.key || undefined,
      nct: data.nct || undefined,
      serie: data.serie?.toString() || undefined,
      emitter: data.emitter || undefined,
      taker: data.taker || undefined,
      operator: data.operator || undefined,
      type: data.type || undefined,
      status: data.status || undefined,
      payment_status: data.payment_status || undefined,
      freight_number: data.freight_number || undefined,
    })
  }

  function handleFormatDate(value: Date | string) {
    const dataFormatada = value
      ? format(utcToZonedTime(value, 'America/Sao_Paulo'), 'dd/MM/yyyy', {
          timeZone: 'America/Sao_Paulo',
          locale: ptLocale,
        })
      : ''
    return dataFormatada
  }

  useEffect(() => {
    getSellerIdToListFreights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const keyQuery = getParams('key')
    const nctQuery = getParams('nct')
    const serieQuery = getParams('serie')
    const emitterQuery = getParams('emitter')
    const takerQuery = getParams('taker')
    const operatorQuery = getParams('operator')
    const typeQuery = getParams('type')
    const statusQuery = getParams('status')
    const paymentStatusQuery = getParams('payment_status')
    const pageQuery = getParams('page')
    const freightNumberQuery = getParams('freight_number')

    if (
      keyQuery ||
      nctQuery ||
      serieQuery ||
      emitterQuery ||
      takerQuery ||
      operatorQuery ||
      typeQuery ||
      statusQuery ||
      paymentStatusQuery ||
      pageQuery ||
      freightNumberQuery
    ) {
      setValue('key', keyQuery || '')
      setValue('nct', nctQuery || '')
      setValue('serie', serieQuery || '')

      setValue('emitter', emitterQuery || '')
      setValue('taker', takerQuery || '')
      setValue('operator', operatorQuery || '')

      setValue('type', typeQuery || '')
      setValue('status', statusQuery || '')
      setValue('payment_status', paymentStatusQuery || '')
      setValue('page', Number(pageQuery) || 1)

      setValue('freight_number', freightNumberQuery || '')

      handleSubmit(handleFilter)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <PageHeader
        title="Lista de CTEs"
        icon={FaClipboardList}
        rightContent={
          <>
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />
      <Filter showFilter={onShowFilter}>
        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <FiltersCtes
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            finalDate={finalDate()}
            initialDate={initialDate()}
          />

          <Flex mt="4" justify="flex-end">
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} px="4" py="2" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between">
          {cteValues &&
            ctes?.data &&
            ctes?.data?.length > 0 &&
            (isLoadingValues ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <Heading size="md" fontWeight="bold">
                Total:{' '}
                <Text fontWeight="light" color="gray.500">
                  {parseCurrency(cteValues.totalValue)}
                </Text>
              </Heading>
            ))}
          <HStack
            display={ctes?.data && ctes?.data?.length > 0 && !isLoading ? 'flex' : 'none'}
            alignSelf="flex-end"
            mb="4"
            justifyContent="flex-end"
            gridGap="2"
          >
            <Ability module="spotHub" action="show-generate-report-ctes">
              <ReportToExcel
                documentTitle="CTes"
                isLoading={false}
                columnsTitle={[
                  'chave',
                  'tipo',
                  'status',
                  'numero',
                  'serie',
                  'emitente',
                  'tomador',
                  'data emissão',
                  'origem',
                  'destino',
                  'valor prest.',
                  'valor receb.',
                  'url do arquivo xml',
                  'pagina do frete',
                ]}
                onGenerateReport={async () => {
                  const report = await fetchCtes({
                    ...filters,
                    emit_date_ini: `${filters.emit_date_ini}`,
                    emit_date_final: `${filters.emit_date_final}`,
                    unpaged: true,
                  })
                  return report.data.map(cte => {
                    return [
                      cte.key,
                      cte.type,
                      cte.status,
                      cte.nct,
                      cte.serie,
                      cte.emitter,
                      cte.taker,
                      cte.emit_date,
                      cte.origin,
                      cte.destination,
                      cte.installment_value_formatted,
                      Number(cte.receive_value),
                      cte.xml_file_url,
                      cte.freight_url,
                    ] as string[]
                  })
                }}
              />
            </Ability>
            <Button
              colorScheme="whatsapp"
              leftIcon={<Icon as={BiSync} fontSize={24} />}
              onClick={async () => {
                await handleUpdateCtesEvents()
                if (isError) {
                  toast({
                    title: 'Não foi possível atualizar os eventos das CTEs',
                    description: 'Tente novamente mais tarde ou contacte o suporte!',
                    status: 'error',
                    isClosable: true,
                    position: 'top-right',
                  })
                } else
                  toast({
                    title: 'Eventos atualizados com sucesso!',
                    status: 'success',
                    isClosable: true,
                    position: 'top-right',
                  })
              }}
              isLoading={isLoadingUpdateCtesEvents}
            >
              Atualizar eventos das ctes
            </Button>
          </HStack>
        </Flex>

        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            {ctes?.data && ctes?.data?.length > 0 && abilityListCte ? (
              <Flex mt="4" w={boxWidth}>
                <Table size="sm" d={displayAdaptable} overflowX="auto">
                  <Thead>
                    <Tr>
                      <Th>NCT</Th>
                      <Th>Série</Th>
                      <Th>Tipo</Th>
                      <Th>Emissão</Th>
                      <Th>Emitente</Th>
                      <Th>Início</Th>
                      <Th>Fim</Th>
                      <Th>Tomador</Th>
                      <Th>Val. Prest.</Th>
                      <Th>Status</Th>
                      <Th>Emissor</Th>
                      <Th>Analista</Th>
                      <Th>VENDEDOR</Th>
                      <Th>Link Frete</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {ctes.data.map(cte => (
                      <Tr key={cte.id}>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          <Tooltip label="Visualizar CTe" hasArrow placement="auto">
                            <Box>
                              <Link href={`/documents-cte-list/show/${cte.id}`} target="_blank">
                                {cte.nct}
                              </Link>
                            </Box>
                          </Tooltip>
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {cte.serie}
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {cte.type}
                        </Td>
                        <Td fontSize="xs">{`${handleFormatDate(cte.emit_date)}`}</Td>
                        <Td fontSize="xs">{cte.emitter}</Td>
                        <Td fontSize="xs">{cte.origin}</Td>
                        <Td fontSize="xs">{cte.destination}</Td>
                        <Td>{cte.taker}</Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {`${parseCurrency(cte.installment_value)}`}
                        </Td>
                        <Td>{cte.status}</Td>
                        <Td>{cte.operator}</Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {cte.freight?.creator?.name}
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {cte.seller}
                        </Td>
                        <Td>
                          {cte.freight_url ? (
                            <Button
                              size="xs"
                              as={Link}
                              href={cte.freight_url}
                              colorScheme="blue"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Frete
                            </Button>
                          ) : undefined}
                        </Td>
                        <Td>
                          <ButtonsActions cte={cte} />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            ) : (
              <Text textAlign="center">Nenhuma CTe encontrada</Text>
            )}
          </>
        )}
        {ctes && ctes.total > 0 && (
          <Box mt={5}>
            <Pagination
              currentPage={filters.page || 1}
              totalCountOfRegisters={ctes.total}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </Box>
    </Layout>
  )
}
