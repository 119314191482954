/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  Alert,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { RiArrowUpSLine, RiAttachmentLine } from 'react-icons/ri'
import { queryClient } from '../../../../config/react-query'
import { AddAttachmentModal } from '../../../../pages/freights/show/cards/attachments/AddAttachmentModal'
import { getLabelAttachmentFreight } from '../../../../services/utils/attachmentsFreightOptions'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { deleteAttachmentFreight } from '../../../../store/slices/freightSlice'
import { AttachmentIcon } from '../../../AttachmentIcon'

interface AccordionTablesProps {
  toggleMoreInfo?: () => void
  showMoreInfo?: boolean
  status: string
}

export const AccordionTables = ({
  showMoreInfo,
  toggleMoreInfo,
  status,
}: AccordionTablesProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { freight, isLoading } = useAppSelector(state => state.freightSlice)
  const {
    isOpen: isOpenAddAttachments,
    onOpen: onOpenAddAttachments,
    onClose: onCloseAddAttachments,
  } = useDisclosure()

  const handleDeleteAttachment = async (idAttach: string) => {
    dispatch(deleteAttachmentFreight(idAttach))
  }

  return (
    <>
      {freight && (
        <>
          <SimpleGrid minChildWidth="300px" spacing="6" columns={2}>
            <Box>
              <Wrap spacing="4" alignItems="center" mb="4">
                <WrapItem flexDirection="column" flex="1">
                  <Flex justify="space-between" align="center">
                    <Heading size="xs" mt="3" color="orange">
                      ANEXOS
                    </Heading>
                    {isLoading && <Spinner size="sm" />}
                  </Flex>
                  {freight.attachments && freight.attachments.length > 0 ? (
                    <Table size="sm" my="1" variant="striped">
                      <Thead>
                        <Tr>
                          <Th>Data</Th>
                          <Th>Tipo</Th>
                          <Th>Descrição</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {freight.attachments.map(attach => {
                          if (attach.type === 'default') {
                            return (
                              <Tr key={attach?.id}>
                                {attach.freight_status === status && (
                                  <>
                                    <Td>{format(new Date(attach.created_at), "dd/MM HH'h'mm")}</Td>
                                    <Td cursor="pointer">
                                      <Tooltip
                                        hasArrow
                                        placement="top"
                                        label={getLabelAttachmentFreight(attach.document)}
                                      >
                                        {getLabelAttachmentFreight(attach.document).substring(0, 10)}
                                      </Tooltip>
                                    </Td>
                                    <Td cursor={attach.description ? 'pointer' : undefined}>
                                      {attach.description && (
                                        <Tooltip
                                          hasArrow
                                          placement="top"
                                          label={attach.description}
                                          color="gray.700"
                                          bg="gray.300"
                                        >
                                          <Text>
                                            {String(attach.description).substring(0, 20)}
                                            {attach.description.length > 20 ? '...' : ''}
                                          </Text>
                                        </Tooltip>
                                      )}
                                    </Td>

                                    <Td>
                                      {attach.attachment_file_url && (
                                        <AttachmentIcon
                                          attachment_file_url={attach.attachment_file_url}
                                          attach_id={attach.id}
                                          handleDeleteAttachment={handleDeleteAttachment}
                                        />
                                      )}
                                    </Td>
                                  </>
                                )}
                              </Tr>
                            )
                          }
                        })}
                      </Tbody>
                    </Table>
                  ) : (
                    <Alert my="3" colorScheme="gray" rounded="md" fontSize="sm">
                      Não há nenhum anexo para esta fase!
                    </Alert>
                  )}

                  <WrapItem>
                    <Button
                      backgroundColor="orange"
                      color="black"
                      size="xs"
                      h="6"
                      ml="1"
                      onClick={onOpenAddAttachments}
                    >
                      <Icon as={RiAttachmentLine} mr="1" /> Novo anexo
                    </Button>
                  </WrapItem>
                </WrapItem>
                <Divider my="1" />
              </Wrap>
            </Box>
          </SimpleGrid>
          <Flex justifyContent="center" mt="-5">
            {showMoreInfo && (
              <IconButton
                mt="6"
                onClick={toggleMoreInfo}
                variant="ghost"
                size="sm"
                aria-label="More info"
                icon={<RiArrowUpSLine />}
              />
            )}
          </Flex>

          <AddAttachmentModal
            freight_status={status}
            isOpen={isOpenAddAttachments}
            onClose={() => {
              onCloseAddAttachments()
              queryClient.invalidateQueries('freight')
              queryClient.invalidateQueries('attachments')
              if (toggleMoreInfo) {
                toggleMoreInfo()
              }
            }}
          />
        </>
      )}
    </>
  )
}
