/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { dispatch } from '../../../../store'
import { deleteAttachmentFreight } from '../../../../store/slices/freightSlice'
import { apiServer } from '../../../api'

export const handleDeleteAttachment = async (idAttach: string): Promise<void> => {
  dispatch(deleteAttachmentFreight(idAttach))
  queryClient.invalidateQueries('list-freight-in-progress')
}

async function deleteOccurrence(id: string) {
  return apiServer.delete(`/freights/attachments/${id}`)
}

export function useDeleteOccurrence(options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>) {
  return useMutation(async (id: string) => deleteOccurrence(id), options)
}
