import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { freightOccurrencesType } from '..'
import { Input, Select, TextArea } from '../../../../../../components/form'

interface ICreateFreightOccurrencesModalProps {
  isOpen: boolean
  onClose: () => void
  handleSubmit: any
  setValue: any
  errors: any
  isLoading: boolean
  handleCreateOccurrence: any
  setAttachments: React.Dispatch<React.SetStateAction<FileList | null>>
}

export default function CreateFreightOccurrencesModal({
  isOpen,
  onClose,
  handleSubmit,
  setValue,
  errors,
  isLoading,
  handleCreateOccurrence,
  setAttachments,
}: ICreateFreightOccurrencesModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Ocorrência</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-occurrence-form" onSubmit={handleSubmit(handleCreateOccurrence)} noValidate>
            <Select
              name="type"
              label="Tipo de Ocorrência"
              setValue={setValue}
              error={errors.type}
              options={freightOccurrencesType}
              mb={2}
              isRequired
            />
            <TextArea
              name="description"
              label="Descrição"
              setValue={setValue}
              error={errors.description}
              height="150px"
              isRequired
            />

            <Input
              name="attachments"
              label="Adicionar anexos"
              onChange={e => setAttachments(e.target.files)}
              type="file"
              multiple
              error={errors.attachments}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button type="submit" form="add-occurrence-form" colorScheme="orange" isLoading={isLoading}>
            Criar Ocorrência
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
